import {UserAddress} from "../types/models/user_address";

interface Country {
  countryId: string;
  displayValue: string;
  stateDisplayValue: null | string;
  cityDisplayValue: null | string;
  postalCodeRequired: boolean;
  stateRequired: boolean;
  postalCodeRegex: null | string;
  displayFormat: string;
  states: Record<string, State>;
}

interface State {
  stateValue: string;
  displayValue: string;
}

interface CountryAndState {
  countries: {
    [countryCode: string]: Country;
  };
}

const countriesAndStates: CountryAndState = {
  "countries": {
    "BA": {
      "countryId": "BA",
      "displayValue": "Bosnia and Herzegovina",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "NP": {
      "countryId": "NP",
      "displayValue": "Nepal",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "states": {}
    },
    "GB": {
      "countryId": "GB",
      "displayValue": "United Kingdom",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^([A-Z]{2}\\d[A-Z](\\s)*\\d[A-Z]{2}|[A-Z]\\d[A-Z](\\s)*\\d[A-Z]{2}|[A-Z]\\d(\\s)*\\d[A-Z]{2}|[A-Z]\\d{2}(\\s)*\\d[A-Z]{2}|[A-Z]{2}\\d(\\s)*\\d[A-Z]{2}|[A-Z]{2}\\d{2}(\\s)*\\d[A-Z]{2})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "states": {}
    },
    "BW": {
      "countryId": "BW",
      "displayValue": "Botswana",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "PT": {
      "countryId": "PT",
      "displayValue": "Portugal",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{4}-\\d{3})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip} {province}_{country}_{phone}",
      "states": {
        "PT-20": {
          "stateValue": "PT-20",
          "displayValue": "Azores"
        },
        "PT-01": {
          "stateValue": "PT-01",
          "displayValue": "Aveiro"
        },
        "PT-02": {
          "stateValue": "PT-02",
          "displayValue": "Beja"
        },
        "PT-03": {
          "stateValue": "PT-03",
          "displayValue": "Braga"
        },
        "PT-04": {
          "stateValue": "PT-04",
          "displayValue": "Bragança"
        },
        "PT-05": {
          "stateValue": "PT-05",
          "displayValue": "Castelo Branco"
        },
        "PT-06": {
          "stateValue": "PT-06",
          "displayValue": "Coimbra"
        },
        "PT-07": {
          "stateValue": "PT-07",
          "displayValue": "Évora"
        },
        "PT-08": {
          "stateValue": "PT-08",
          "displayValue": "Faro"
        },
        "PT-09": {
          "stateValue": "PT-09",
          "displayValue": "Guarda"
        },
        "PT-10": {
          "stateValue": "PT-10",
          "displayValue": "Leiria"
        },
        "PT-11": {
          "stateValue": "PT-11",
          "displayValue": "Lisbon"
        },
        "PT-30": {
          "stateValue": "PT-30",
          "displayValue": "Madeira"
        },
        "PT-12": {
          "stateValue": "PT-12",
          "displayValue": "Portalegre"
        },
        "PT-13": {
          "stateValue": "PT-13",
          "displayValue": "Porto"
        },
        "PT-14": {
          "stateValue": "PT-14",
          "displayValue": "Santarém"
        },
        "PT-15": {
          "stateValue": "PT-15",
          "displayValue": "Setúbal"
        },
        "PT-16": {
          "stateValue": "PT-16",
          "displayValue": "Viana do Castelo"
        },
        "PT-17": {
          "stateValue": "PT-17",
          "displayValue": "Vila Real"
        },
        "PT-18": {
          "stateValue": "PT-18",
          "displayValue": "Viseu"
        }
      }
    },
    "RS": {
      "countryId": "RS",
      "displayValue": "Serbia",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{6})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "TZ": {
      "countryId": "TZ",
      "displayValue": "Tanzania, United Republic Of",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "KY": {
      "countryId": "KY",
      "displayValue": "Cayman Islands",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(KY\\d-\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "KR": {
      "countryId": "KR",
      "displayValue": "South Korea",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{province}_{country}_{phone}",
      "states": {
        "KR-26": {
          "stateValue": "KR-26",
          "displayValue": "Busan"
        },
        "KR-43": {
          "stateValue": "KR-43",
          "displayValue": "North Chungcheong"
        },
        "KR-44": {
          "stateValue": "KR-44",
          "displayValue": "South Chungcheong"
        },
        "KR-27": {
          "stateValue": "KR-27",
          "displayValue": "Daegu"
        },
        "KR-30": {
          "stateValue": "KR-30",
          "displayValue": "Daejeon"
        },
        "KR-42": {
          "stateValue": "KR-42",
          "displayValue": "Gangwon"
        },
        "KR-29": {
          "stateValue": "KR-29",
          "displayValue": "Gwangju City"
        },
        "KR-47": {
          "stateValue": "KR-47",
          "displayValue": "North Gyeongsang"
        },
        "KR-41": {
          "stateValue": "KR-41",
          "displayValue": "Gyeonggi"
        },
        "KR-48": {
          "stateValue": "KR-48",
          "displayValue": "South Gyeongsang"
        },
        "KR-28": {
          "stateValue": "KR-28",
          "displayValue": "Incheon"
        },
        "KR-49": {
          "stateValue": "KR-49",
          "displayValue": "Jeju"
        },
        "KR-45": {
          "stateValue": "KR-45",
          "displayValue": "North Jeolla"
        },
        "KR-46": {
          "stateValue": "KR-46",
          "displayValue": "South Jeolla"
        },
        "KR-50": {
          "stateValue": "KR-50",
          "displayValue": "Sejong"
        },
        "KR-11": {
          "stateValue": "KR-11",
          "displayValue": "Seoul"
        },
        "KR-31": {
          "stateValue": "KR-31",
          "displayValue": "Ulsan"
        }
      }
    },
    "GI": {
      "countryId": "GI",
      "displayValue": "Gibraltar",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "BR": {
      "countryId": "BR",
      "displayValue": "Brazil",
      "stateDisplayValue": "State",
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{5}-?\\d{3})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {province}_{zip}_{country}_{phone}",
      "states": {
        "AC": {
          "stateValue": "AC",
          "displayValue": "Acre"
        },
        "AL": {
          "stateValue": "AL",
          "displayValue": "Alagoas"
        },
        "AP": {
          "stateValue": "AP",
          "displayValue": "Amapá"
        },
        "AM": {
          "stateValue": "AM",
          "displayValue": "Amazonas"
        },
        "BA": {
          "stateValue": "BA",
          "displayValue": "Bahia"
        },
        "CE": {
          "stateValue": "CE",
          "displayValue": "Ceará"
        },
        "DF": {
          "stateValue": "DF",
          "displayValue": "Federal District"
        },
        "ES": {
          "stateValue": "ES",
          "displayValue": "Espírito Santo"
        },
        "GO": {
          "stateValue": "GO",
          "displayValue": "Goiás"
        },
        "MA": {
          "stateValue": "MA",
          "displayValue": "Maranhão"
        },
        "MT": {
          "stateValue": "MT",
          "displayValue": "Mato Grosso"
        },
        "MS": {
          "stateValue": "MS",
          "displayValue": "Mato Grosso do Sul"
        },
        "MG": {
          "stateValue": "MG",
          "displayValue": "Minas Gerais"
        },
        "PA": {
          "stateValue": "PA",
          "displayValue": "Pará"
        },
        "PB": {
          "stateValue": "PB",
          "displayValue": "Paraíba"
        },
        "PR": {
          "stateValue": "PR",
          "displayValue": "Paraná"
        },
        "PE": {
          "stateValue": "PE",
          "displayValue": "Pernambuco"
        },
        "PI": {
          "stateValue": "PI",
          "displayValue": "Piauí"
        },
        "RN": {
          "stateValue": "RN",
          "displayValue": "Rio Grande do Norte"
        },
        "RS": {
          "stateValue": "RS",
          "displayValue": "Rio Grande do Sul"
        },
        "RJ": {
          "stateValue": "RJ",
          "displayValue": "Rio de Janeiro"
        },
        "RO": {
          "stateValue": "RO",
          "displayValue": "Rondônia"
        },
        "RR": {
          "stateValue": "RR",
          "displayValue": "Roraima"
        },
        "SC": {
          "stateValue": "SC",
          "displayValue": "Santa Catarina"
        },
        "SP": {
          "stateValue": "SP",
          "displayValue": "São Paulo"
        },
        "SE": {
          "stateValue": "SE",
          "displayValue": "Sergipe"
        },
        "TO": {
          "stateValue": "TO",
          "displayValue": "Tocantins"
        }
      }
    },
    "JM": {
      "countryId": "JM",
      "displayValue": "Jamaica",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "LB": {
      "countryId": "LB",
      "displayValue": "Lebanon",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4}(\\s)*\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "states": {}
    },
    "BS": {
      "countryId": "BS",
      "displayValue": "Bahamas",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "MA": {
      "countryId": "MA",
      "displayValue": "Morocco",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "IL": {
      "countryId": "IL",
      "displayValue": "Israel",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{7})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "BE": {
      "countryId": "BE",
      "displayValue": "Belgium",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "PE": {
      "countryId": "PE",
      "displayValue": "Peru",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": true,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{province}_{country}_{phone}",
      "states": {
        "PE-AMA": {
          "stateValue": "PE-AMA",
          "displayValue": "Amazonas"
        },
        "PE-ANC": {
          "stateValue": "PE-ANC",
          "displayValue": "Ancash"
        },
        "PE-APU": {
          "stateValue": "PE-APU",
          "displayValue": "Apurímac"
        },
        "PE-ARE": {
          "stateValue": "PE-ARE",
          "displayValue": "Arequipa"
        },
        "PE-AYA": {
          "stateValue": "PE-AYA",
          "displayValue": "Ayacucho"
        },
        "PE-CAJ": {
          "stateValue": "PE-CAJ",
          "displayValue": "Cajamarca"
        },
        "PE-CAL": {
          "stateValue": "PE-CAL",
          "displayValue": "El Callao"
        },
        "PE-CUS": {
          "stateValue": "PE-CUS",
          "displayValue": "Cusco"
        },
        "PE-HUV": {
          "stateValue": "PE-HUV",
          "displayValue": "Huancavelica"
        },
        "PE-HUC": {
          "stateValue": "PE-HUC",
          "displayValue": "Huánuco"
        },
        "PE-ICA": {
          "stateValue": "PE-ICA",
          "displayValue": "Ica"
        },
        "PE-JUN": {
          "stateValue": "PE-JUN",
          "displayValue": "Junín"
        },
        "PE-LAL": {
          "stateValue": "PE-LAL",
          "displayValue": "La Libertad"
        },
        "PE-LAM": {
          "stateValue": "PE-LAM",
          "displayValue": "Lambayeque"
        },
        "PE-LIM": {
          "stateValue": "PE-LIM",
          "displayValue": "Lima Region"
        },
        "PE-LMA": {
          "stateValue": "PE-LMA",
          "displayValue": "Lima"
        },
        "PE-LOR": {
          "stateValue": "PE-LOR",
          "displayValue": "Loreto"
        },
        "PE-MDD": {
          "stateValue": "PE-MDD",
          "displayValue": "Madre de Dios"
        },
        "PE-MOQ": {
          "stateValue": "PE-MOQ",
          "displayValue": "Moquegua"
        },
        "PE-PAS": {
          "stateValue": "PE-PAS",
          "displayValue": "Pasco"
        },
        "PE-PIU": {
          "stateValue": "PE-PIU",
          "displayValue": "Piura"
        },
        "PE-PUN": {
          "stateValue": "PE-PUN",
          "displayValue": "Puno"
        },
        "PE-SAM": {
          "stateValue": "PE-SAM",
          "displayValue": "San Martín"
        },
        "PE-TAC": {
          "stateValue": "PE-TAC",
          "displayValue": "Tacna"
        },
        "PE-TUM": {
          "stateValue": "PE-TUM",
          "displayValue": "Tumbes"
        },
        "PE-UCA": {
          "stateValue": "PE-UCA",
          "displayValue": "Ucayali"
        }
      }
    },
    "DM": {
      "countryId": "DM",
      "displayValue": "Dominica",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "LA": {
      "countryId": "LA",
      "displayValue": "Lao People's Democratic Republic",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "GP": {
      "countryId": "GP",
      "displayValue": "Guadeloupe",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "AO": {
      "countryId": "AO",
      "displayValue": "Angola",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "KN": {
      "countryId": "KN",
      "displayValue": "Saint Kitts and Nevis",
      "stateDisplayValue": "Province",
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "HU": {
      "countryId": "HU",
      "displayValue": "Hungary",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "states": {}
    },
    "PF": {
      "countryId": "PF",
      "displayValue": "French Polynesia",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "GY": {
      "countryId": "GY",
      "displayValue": "Guyana",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "LI": {
      "countryId": "LI",
      "displayValue": "Liechtenstein",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "NG": {
      "countryId": "NG",
      "displayValue": "Nigeria",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{6})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
      "states": {
        "AB": {
          "stateValue": "AB",
          "displayValue": "Abia"
        },
        "FC": {
          "stateValue": "FC",
          "displayValue": "Federal Capital Territory"
        },
        "AD": {
          "stateValue": "AD",
          "displayValue": "Adamawa"
        },
        "AK": {
          "stateValue": "AK",
          "displayValue": "Akwa Ibom"
        },
        "AN": {
          "stateValue": "AN",
          "displayValue": "Anambra"
        },
        "BA": {
          "stateValue": "BA",
          "displayValue": "Bauchi"
        },
        "BY": {
          "stateValue": "BY",
          "displayValue": "Bayelsa"
        },
        "BE": {
          "stateValue": "BE",
          "displayValue": "Benue"
        },
        "BO": {
          "stateValue": "BO",
          "displayValue": "Borno"
        },
        "CR": {
          "stateValue": "CR",
          "displayValue": "Cross River"
        },
        "DE": {
          "stateValue": "DE",
          "displayValue": "Delta"
        },
        "EB": {
          "stateValue": "EB",
          "displayValue": "Ebonyi"
        },
        "ED": {
          "stateValue": "ED",
          "displayValue": "Edo"
        },
        "EK": {
          "stateValue": "EK",
          "displayValue": "Ekiti"
        },
        "EN": {
          "stateValue": "EN",
          "displayValue": "Enugu"
        },
        "GO": {
          "stateValue": "GO",
          "displayValue": "Gombe"
        },
        "IM": {
          "stateValue": "IM",
          "displayValue": "Imo"
        },
        "JI": {
          "stateValue": "JI",
          "displayValue": "Jigawa"
        },
        "KD": {
          "stateValue": "KD",
          "displayValue": "Kaduna"
        },
        "KN": {
          "stateValue": "KN",
          "displayValue": "Kano"
        },
        "KT": {
          "stateValue": "KT",
          "displayValue": "Katsina"
        },
        "KE": {
          "stateValue": "KE",
          "displayValue": "Kebbi"
        },
        "KO": {
          "stateValue": "KO",
          "displayValue": "Kogi"
        },
        "KW": {
          "stateValue": "KW",
          "displayValue": "Kwara"
        },
        "LA": {
          "stateValue": "LA",
          "displayValue": "Lagos"
        },
        "NA": {
          "stateValue": "NA",
          "displayValue": "Nasarawa"
        },
        "NI": {
          "stateValue": "NI",
          "displayValue": "Niger"
        },
        "OG": {
          "stateValue": "OG",
          "displayValue": "Ogun"
        },
        "ON": {
          "stateValue": "ON",
          "displayValue": "Ondo"
        },
        "OS": {
          "stateValue": "OS",
          "displayValue": "Osun"
        },
        "OY": {
          "stateValue": "OY",
          "displayValue": "Oyo"
        },
        "PL": {
          "stateValue": "PL",
          "displayValue": "Plateau"
        },
        "RI": {
          "stateValue": "RI",
          "displayValue": "Rivers"
        },
        "SO": {
          "stateValue": "SO",
          "displayValue": "Sokoto"
        },
        "TA": {
          "stateValue": "TA",
          "displayValue": "Taraba"
        },
        "YO": {
          "stateValue": "YO",
          "displayValue": "Yobe"
        },
        "ZA": {
          "stateValue": "ZA",
          "displayValue": "Zamfara"
        }
      }
    },
    "TN": {
      "countryId": "TN",
      "displayValue": "Tunisia",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "NE": {
      "countryId": "NE",
      "displayValue": "Niger",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "MK": {
      "countryId": "MK",
      "displayValue": "Macedonia, The Former Yugoslav Republic Of",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "SC": {
      "countryId": "SC",
      "displayValue": "Seychelles",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "AM": {
      "countryId": "AM",
      "displayValue": "Armenia",
      "stateDisplayValue": "Region or City",
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "JO": {
      "countryId": "JO",
      "displayValue": "Jordan",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "states": {}
    },
    "AF": {
      "countryId": "AF",
      "displayValue": "Afghanistan",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "FJ": {
      "countryId": "FJ",
      "displayValue": "Fiji",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "SI": {
      "countryId": "SI",
      "displayValue": "Slovenia",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "MO": {
      "countryId": "MO",
      "displayValue": "Macao",
      "stateDisplayValue": null,
      "cityDisplayValue": "District",
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "MY": {
      "countryId": "MY",
      "displayValue": "Malaysia",
      "stateDisplayValue": "State",
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
      "states": {
        "JHR": {
          "stateValue": "JHR",
          "displayValue": "Johor"
        },
        "KDH": {
          "stateValue": "KDH",
          "displayValue": "Kedah"
        },
        "KTN": {
          "stateValue": "KTN",
          "displayValue": "Kelantan"
        },
        "KUL": {
          "stateValue": "KUL",
          "displayValue": "Kuala Lumpur"
        },
        "LBN": {
          "stateValue": "LBN",
          "displayValue": "Labuan"
        },
        "MLK": {
          "stateValue": "MLK",
          "displayValue": "Malacca"
        },
        "NSN": {
          "stateValue": "NSN",
          "displayValue": "Negeri Sembilan"
        },
        "PHG": {
          "stateValue": "PHG",
          "displayValue": "Pahang"
        },
        "PNG": {
          "stateValue": "PNG",
          "displayValue": "Penang"
        },
        "PRK": {
          "stateValue": "PRK",
          "displayValue": "Perak"
        },
        "PLS": {
          "stateValue": "PLS",
          "displayValue": "Perlis"
        },
        "PJY": {
          "stateValue": "PJY",
          "displayValue": "Putrajaya"
        },
        "SBH": {
          "stateValue": "SBH",
          "displayValue": "Sabah"
        },
        "SWK": {
          "stateValue": "SWK",
          "displayValue": "Sarawak"
        },
        "SGR": {
          "stateValue": "SGR",
          "displayValue": "Selangor"
        },
        "TRG": {
          "stateValue": "TRG",
          "displayValue": "Terengganu"
        }
      }
    },
    "HK": {
      "countryId": "HK",
      "displayValue": "Hong Kong",
      "stateDisplayValue": null,
      "cityDisplayValue": "District",
      "postalCodeRequired": false,
      "stateRequired": true,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{province} {country}_{phone}",
      "states": {
        "HK": {
          "stateValue": "HK",
          "displayValue": "Hong Kong Island"
        },
        "KL": {
          "stateValue": "KL",
          "displayValue": "Kowloon"
        },
        "NT": {
          "stateValue": "NT",
          "displayValue": "New Territories"
        }
      }
    },
    "BB": {
      "countryId": "BB",
      "displayValue": "Barbados",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(BB\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "FO": {
      "countryId": "FO",
      "displayValue": "Faroe Islands",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{3})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "LC": {
      "countryId": "LC",
      "displayValue": "Saint Lucia",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "IQ": {
      "countryId": "IQ",
      "displayValue": "Iraq",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "states": {}
    },
    "AL": {
      "countryId": "AL",
      "displayValue": "Albania",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "GL": {
      "countryId": "GL",
      "displayValue": "Greenland",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "GA": {
      "countryId": "GA",
      "displayValue": "Gabon",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{2})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "PH": {
      "countryId": "PH",
      "displayValue": "Philippines",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{province}_{country}_{phone}",
      "states": {
        "PH-ABR": {
          "stateValue": "PH-ABR",
          "displayValue": "Abra"
        },
        "PH-AGN": {
          "stateValue": "PH-AGN",
          "displayValue": "Agusan del Norte"
        },
        "PH-AGS": {
          "stateValue": "PH-AGS",
          "displayValue": "Agusan del Sur"
        },
        "PH-AKL": {
          "stateValue": "PH-AKL",
          "displayValue": "Aklan"
        },
        "PH-ALB": {
          "stateValue": "PH-ALB",
          "displayValue": "Albay"
        },
        "PH-ANT": {
          "stateValue": "PH-ANT",
          "displayValue": "Antique"
        },
        "PH-APA": {
          "stateValue": "PH-APA",
          "displayValue": "Apayao"
        },
        "PH-AUR": {
          "stateValue": "PH-AUR",
          "displayValue": "Aurora"
        },
        "PH-BAS": {
          "stateValue": "PH-BAS",
          "displayValue": "Basilan"
        },
        "PH-BAN": {
          "stateValue": "PH-BAN",
          "displayValue": "Bataan"
        },
        "PH-BTN": {
          "stateValue": "PH-BTN",
          "displayValue": "Batanes"
        },
        "PH-BTG": {
          "stateValue": "PH-BTG",
          "displayValue": "Batangas"
        },
        "PH-BEN": {
          "stateValue": "PH-BEN",
          "displayValue": "Benguet"
        },
        "PH-BIL": {
          "stateValue": "PH-BIL",
          "displayValue": "Biliran"
        },
        "PH-BOH": {
          "stateValue": "PH-BOH",
          "displayValue": "Bohol"
        },
        "PH-BUK": {
          "stateValue": "PH-BUK",
          "displayValue": "Bukidnon"
        },
        "PH-BUL": {
          "stateValue": "PH-BUL",
          "displayValue": "Bulacan"
        },
        "PH-CAG": {
          "stateValue": "PH-CAG",
          "displayValue": "Cagayan"
        },
        "PH-CAN": {
          "stateValue": "PH-CAN",
          "displayValue": "Camarines Norte"
        },
        "PH-CAS": {
          "stateValue": "PH-CAS",
          "displayValue": "Camarines Sur"
        },
        "PH-CAM": {
          "stateValue": "PH-CAM",
          "displayValue": "Camiguin"
        },
        "PH-CAP": {
          "stateValue": "PH-CAP",
          "displayValue": "Capiz"
        },
        "PH-CAT": {
          "stateValue": "PH-CAT",
          "displayValue": "Catanduanes"
        },
        "PH-CAV": {
          "stateValue": "PH-CAV",
          "displayValue": "Cavite"
        },
        "PH-CEB": {
          "stateValue": "PH-CEB",
          "displayValue": "Cebu"
        },
        "PH-NCO": {
          "stateValue": "PH-NCO",
          "displayValue": "Cotabato"
        },
        "PH-DVO": {
          "stateValue": "PH-DVO",
          "displayValue": "Davao Occidental"
        },
        "PH-DAO": {
          "stateValue": "PH-DAO",
          "displayValue": "Davao Oriental"
        },
        "PH-COM": {
          "stateValue": "PH-COM",
          "displayValue": "Compostela Valley"
        },
        "PH-DAV": {
          "stateValue": "PH-DAV",
          "displayValue": "Davao del Norte"
        },
        "PH-DAS": {
          "stateValue": "PH-DAS",
          "displayValue": "Davao del Sur"
        },
        "PH-DIN": {
          "stateValue": "PH-DIN",
          "displayValue": "Dinagat Islands"
        },
        "PH-EAS": {
          "stateValue": "PH-EAS",
          "displayValue": "Eastern Samar"
        },
        "PH-GUI": {
          "stateValue": "PH-GUI",
          "displayValue": "Guimaras"
        },
        "PH-IFU": {
          "stateValue": "PH-IFU",
          "displayValue": "Ifugao"
        },
        "PH-ILN": {
          "stateValue": "PH-ILN",
          "displayValue": "Ilocos Norte"
        },
        "PH-ILS": {
          "stateValue": "PH-ILS",
          "displayValue": "Ilocos Sur"
        },
        "PH-ILI": {
          "stateValue": "PH-ILI",
          "displayValue": "Iloilo"
        },
        "PH-ISA": {
          "stateValue": "PH-ISA",
          "displayValue": "Isabela"
        },
        "PH-KAL": {
          "stateValue": "PH-KAL",
          "displayValue": "Kalinga"
        },
        "PH-LUN": {
          "stateValue": "PH-LUN",
          "displayValue": "La Union"
        },
        "PH-LAG": {
          "stateValue": "PH-LAG",
          "displayValue": "Laguna"
        },
        "PH-LAN": {
          "stateValue": "PH-LAN",
          "displayValue": "Lanao del Norte"
        },
        "PH-LAS": {
          "stateValue": "PH-LAS",
          "displayValue": "Lanao del Sur"
        },
        "PH-LEY": {
          "stateValue": "PH-LEY",
          "displayValue": "Leyte"
        },
        "PH-MAG": {
          "stateValue": "PH-MAG",
          "displayValue": "Maguindanao"
        },
        "PH-MAD": {
          "stateValue": "PH-MAD",
          "displayValue": "Marinduque"
        },
        "PH-MAS": {
          "stateValue": "PH-MAS",
          "displayValue": "Masbate"
        },
        "PH-00": {
          "stateValue": "PH-00",
          "displayValue": "Metro Manila"
        },
        "PH-MSC": {
          "stateValue": "PH-MSC",
          "displayValue": "Misamis Occidental"
        },
        "PH-MSR": {
          "stateValue": "PH-MSR",
          "displayValue": "Misamis Oriental"
        },
        "PH-MOU": {
          "stateValue": "PH-MOU",
          "displayValue": "Mountain"
        },
        "PH-NEC": {
          "stateValue": "PH-NEC",
          "displayValue": "Negros Occidental"
        },
        "PH-NER": {
          "stateValue": "PH-NER",
          "displayValue": "Negros Oriental"
        },
        "PH-NSA": {
          "stateValue": "PH-NSA",
          "displayValue": "Northern Samar"
        },
        "PH-NUE": {
          "stateValue": "PH-NUE",
          "displayValue": "Nueva Ecija"
        },
        "PH-NUV": {
          "stateValue": "PH-NUV",
          "displayValue": "Nueva Vizcaya"
        },
        "PH-MDC": {
          "stateValue": "PH-MDC",
          "displayValue": "Occidental Mindoro"
        },
        "PH-MDR": {
          "stateValue": "PH-MDR",
          "displayValue": "Oriental Mindoro"
        },
        "PH-PLW": {
          "stateValue": "PH-PLW",
          "displayValue": "Palawan"
        },
        "PH-PAM": {
          "stateValue": "PH-PAM",
          "displayValue": "Pampanga"
        },
        "PH-PAN": {
          "stateValue": "PH-PAN",
          "displayValue": "Pangasinan"
        },
        "PH-QUE": {
          "stateValue": "PH-QUE",
          "displayValue": "Quezon"
        },
        "PH-QUI": {
          "stateValue": "PH-QUI",
          "displayValue": "Quirino"
        },
        "PH-RIZ": {
          "stateValue": "PH-RIZ",
          "displayValue": "Rizal"
        },
        "PH-ROM": {
          "stateValue": "PH-ROM",
          "displayValue": "Romblon"
        },
        "PH-WSA": {
          "stateValue": "PH-WSA",
          "displayValue": "Samar"
        },
        "PH-SAR": {
          "stateValue": "PH-SAR",
          "displayValue": "Sarangani"
        },
        "PH-SIG": {
          "stateValue": "PH-SIG",
          "displayValue": "Siquijor"
        },
        "PH-SOR": {
          "stateValue": "PH-SOR",
          "displayValue": "Sorsogon"
        },
        "PH-SCO": {
          "stateValue": "PH-SCO",
          "displayValue": "South Cotabato"
        },
        "PH-SLE": {
          "stateValue": "PH-SLE",
          "displayValue": "Southern Leyte"
        },
        "PH-SUK": {
          "stateValue": "PH-SUK",
          "displayValue": "Sultan Kudarat"
        },
        "PH-SLU": {
          "stateValue": "PH-SLU",
          "displayValue": "Sulu"
        },
        "PH-SUN": {
          "stateValue": "PH-SUN",
          "displayValue": "Surigao del Norte"
        },
        "PH-SUR": {
          "stateValue": "PH-SUR",
          "displayValue": "Surigao del Sur"
        },
        "PH-TAR": {
          "stateValue": "PH-TAR",
          "displayValue": "Tarlac"
        },
        "PH-TAW": {
          "stateValue": "PH-TAW",
          "displayValue": "Tawi-Tawi"
        },
        "PH-ZMB": {
          "stateValue": "PH-ZMB",
          "displayValue": "Zambales"
        },
        "PH-ZSI": {
          "stateValue": "PH-ZSI",
          "displayValue": "Zamboanga Sibugay"
        },
        "PH-ZAN": {
          "stateValue": "PH-ZAN",
          "displayValue": "Zamboanga del Norte"
        },
        "PH-ZAS": {
          "stateValue": "PH-ZAS",
          "displayValue": "Zamboanga del Sur"
        }
      }
    },
    "BI": {
      "countryId": "BI",
      "displayValue": "Burundi",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "TR": {
      "countryId": "TR",
      "displayValue": "Turkey",
      "stateDisplayValue": "Province",
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "RO": {
      "countryId": "RO",
      "displayValue": "Romania",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{6})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
      "states": {
        "AB": {
          "stateValue": "AB",
          "displayValue": "Alba"
        },
        "AR": {
          "stateValue": "AR",
          "displayValue": "Arad"
        },
        "AG": {
          "stateValue": "AG",
          "displayValue": "Argeș"
        },
        "BC": {
          "stateValue": "BC",
          "displayValue": "Bacău"
        },
        "BH": {
          "stateValue": "BH",
          "displayValue": "Bihor"
        },
        "BN": {
          "stateValue": "BN",
          "displayValue": "Bistriţa-Năsăud"
        },
        "BT": {
          "stateValue": "BT",
          "displayValue": "Botoşani"
        },
        "BR": {
          "stateValue": "BR",
          "displayValue": "Brăila"
        },
        "BV": {
          "stateValue": "BV",
          "displayValue": "Braşov"
        },
        "B": {
          "stateValue": "B",
          "displayValue": "Bucharest"
        },
        "BZ": {
          "stateValue": "BZ",
          "displayValue": "Buzău"
        },
        "CS": {
          "stateValue": "CS",
          "displayValue": "Caraș-Severin"
        },
        "CJ": {
          "stateValue": "CJ",
          "displayValue": "Cluj"
        },
        "CT": {
          "stateValue": "CT",
          "displayValue": "Constanța"
        },
        "CV": {
          "stateValue": "CV",
          "displayValue": "Covasna"
        },
        "CL": {
          "stateValue": "CL",
          "displayValue": "Călărași"
        },
        "DJ": {
          "stateValue": "DJ",
          "displayValue": "Dolj"
        },
        "DB": {
          "stateValue": "DB",
          "displayValue": "Dâmbovița"
        },
        "GL": {
          "stateValue": "GL",
          "displayValue": "Galați"
        },
        "GR": {
          "stateValue": "GR",
          "displayValue": "Giurgiu"
        },
        "GJ": {
          "stateValue": "GJ",
          "displayValue": "Gorj"
        },
        "HR": {
          "stateValue": "HR",
          "displayValue": "Harghita"
        },
        "HD": {
          "stateValue": "HD",
          "displayValue": "Hunedoara"
        },
        "IL": {
          "stateValue": "IL",
          "displayValue": "Ialomița"
        },
        "IS": {
          "stateValue": "IS",
          "displayValue": "Iași"
        },
        "IF": {
          "stateValue": "IF",
          "displayValue": "Ilfov"
        },
        "MM": {
          "stateValue": "MM",
          "displayValue": "Maramureş"
        },
        "MH": {
          "stateValue": "MH",
          "displayValue": "Mehedinți"
        },
        "MS": {
          "stateValue": "MS",
          "displayValue": "Mureş"
        },
        "NT": {
          "stateValue": "NT",
          "displayValue": "Neamţ"
        },
        "OT": {
          "stateValue": "OT",
          "displayValue": "Olt"
        },
        "PH": {
          "stateValue": "PH",
          "displayValue": "Prahova"
        },
        "SJ": {
          "stateValue": "SJ",
          "displayValue": "Sălaj"
        },
        "SM": {
          "stateValue": "SM",
          "displayValue": "Satu Mare"
        },
        "SB": {
          "stateValue": "SB",
          "displayValue": "Sibiu"
        },
        "SV": {
          "stateValue": "SV",
          "displayValue": "Suceava"
        },
        "TR": {
          "stateValue": "TR",
          "displayValue": "Teleorman"
        },
        "TM": {
          "stateValue": "TM",
          "displayValue": "Timiș"
        },
        "TL": {
          "stateValue": "TL",
          "displayValue": "Tulcea"
        },
        "VL": {
          "stateValue": "VL",
          "displayValue": "Vâlcea"
        },
        "VS": {
          "stateValue": "VS",
          "displayValue": "Vaslui"
        },
        "VN": {
          "stateValue": "VN",
          "displayValue": "Vrancea"
        }
      }
    },
    "BJ": {
      "countryId": "BJ",
      "displayValue": "Benin",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{2}(\\s)*BP(\\s)*\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "GG": {
      "countryId": "GG",
      "displayValue": "Guernsey",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(GY\\d{0,2}(\\s)*\\d[A-Z]{2})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "VC": {
      "countryId": "VC",
      "displayValue": "Saint Vincent and the Grenadines",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "NL": {
      "countryId": "NL",
      "displayValue": "Netherlands",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4}(\\s)*[A-Z]{2})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "UY": {
      "countryId": "UY",
      "displayValue": "Uruguay",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "MD": {
      "countryId": "MD",
      "displayValue": "Moldova, Republic Of",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(MD-\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "NA": {
      "countryId": "NA",
      "displayValue": "Namibia",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "JE": {
      "countryId": "JE",
      "displayValue": "Jersey",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(JE\\d(\\s)*\\d[A-Z]{2})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "KH": {
      "countryId": "KH",
      "displayValue": "Cambodia",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "states": {}
    },
    "CI": {
      "countryId": "CI",
      "displayValue": "Côte d'Ivoire",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(BP(\\s)*\\d{2})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "DZ": {
      "countryId": "DZ",
      "displayValue": "Algeria",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "MU": {
      "countryId": "MU",
      "displayValue": "Mauritius",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{3}[A-Z]{2}\\d{3})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip}_{city}_{country}_{phone}",
      "states": {}
    },
    "ZA": {
      "countryId": "ZA",
      "displayValue": "South Africa",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{province}_{zip}_{country}_{phone}",
      "states": {
        "EC": {
          "stateValue": "EC",
          "displayValue": "Eastern Cape"
        },
        "FS": {
          "stateValue": "FS",
          "displayValue": "Free State"
        },
        "GT": {
          "stateValue": "GT",
          "displayValue": "Gauteng"
        },
        "NL": {
          "stateValue": "NL",
          "displayValue": "KwaZulu-Natal"
        },
        "LP": {
          "stateValue": "LP",
          "displayValue": "Limpopo"
        },
        "MP": {
          "stateValue": "MP",
          "displayValue": "Mpumalanga"
        },
        "NW": {
          "stateValue": "NW",
          "displayValue": "North West"
        },
        "NC": {
          "stateValue": "NC",
          "displayValue": "Northern Cape"
        },
        "WC": {
          "stateValue": "WC",
          "displayValue": "Western Cape"
        }
      }
    },
    "GD": {
      "countryId": "GD",
      "displayValue": "Grenada",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "KZ": {
      "countryId": "KZ",
      "displayValue": "Kazakhstan",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{6})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "states": {}
    },
    "HR": {
      "countryId": "HR",
      "displayValue": "Croatia",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "SA": {
      "countryId": "SA",
      "displayValue": "Saudi Arabia",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "states": {}
    },
    "NZ": {
      "countryId": "NZ",
      "displayValue": "New Zealand",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{province}_{city} {zip}_{country}_{phone}",
      "states": {
        "AUK": {
          "stateValue": "AUK",
          "displayValue": "Auckland"
        },
        "BOP": {
          "stateValue": "BOP",
          "displayValue": "Bay of Plenty"
        },
        "CAN": {
          "stateValue": "CAN",
          "displayValue": "Canterbury"
        },
        "GIS": {
          "stateValue": "GIS",
          "displayValue": "Gisborne"
        },
        "HKB": {
          "stateValue": "HKB",
          "displayValue": "Hawke’s Bay"
        },
        "MWT": {
          "stateValue": "MWT",
          "displayValue": "Manawatu-Wanganui"
        },
        "MBH": {
          "stateValue": "MBH",
          "displayValue": "Marlborough"
        },
        "NSN": {
          "stateValue": "NSN",
          "displayValue": "Nelson"
        },
        "NTL": {
          "stateValue": "NTL",
          "displayValue": "Northland"
        },
        "OTA": {
          "stateValue": "OTA",
          "displayValue": "Otago"
        },
        "STL": {
          "stateValue": "STL",
          "displayValue": "Southland"
        },
        "TKI": {
          "stateValue": "TKI",
          "displayValue": "Taranaki"
        },
        "TAS": {
          "stateValue": "TAS",
          "displayValue": "Tasman"
        },
        "WKO": {
          "stateValue": "WKO",
          "displayValue": "Waikato"
        },
        "WGN": {
          "stateValue": "WGN",
          "displayValue": "Wellington"
        },
        "WTC": {
          "stateValue": "WTC",
          "displayValue": "West Coast"
        }
      }
    },
    "WS": {
      "countryId": "WS",
      "displayValue": "Samoa",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "HT": {
      "countryId": "HT",
      "displayValue": "Haiti",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "SE": {
      "countryId": "SE",
      "displayValue": "Sweden",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{3}(\\s)*\\d{2})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "HN": {
      "countryId": "HN",
      "displayValue": "Honduras",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "BY": {
      "countryId": "BY",
      "displayValue": "Belarus",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{6})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "LK": {
      "countryId": "LK",
      "displayValue": "Sri Lanka",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "states": {}
    },
    "BH": {
      "countryId": "BH",
      "displayValue": "Bahrain",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{3}|\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "states": {}
    },
    "NI": {
      "countryId": "NI",
      "displayValue": "Nicaragua",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{3}-\\d{3}-\\d{1})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip}_{city}_{country}_{phone}",
      "states": {}
    },
    "MV": {
      "countryId": "MV",
      "displayValue": "Maldives",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{2}-\\d{2})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "states": {}
    },
    "TO": {
      "countryId": "TO",
      "displayValue": "Tonga",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "SK": {
      "countryId": "SK",
      "displayValue": "Slovakia",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{3}(\\s)*\\d{2})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "GM": {
      "countryId": "GM",
      "displayValue": "Gambia",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "GT": {
      "countryId": "GT",
      "displayValue": "Guatemala",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {province}_{zip}_{country}_{phone}",
      "states": {
        "AVE": {
          "stateValue": "AVE",
          "displayValue": "Alta Verapaz"
        },
        "BVE": {
          "stateValue": "BVE",
          "displayValue": "Baja Verapaz"
        },
        "CMT": {
          "stateValue": "CMT",
          "displayValue": "Chimaltenango"
        },
        "CQM": {
          "stateValue": "CQM",
          "displayValue": "Chiquimula"
        },
        "EPR": {
          "stateValue": "EPR",
          "displayValue": "El Progreso"
        },
        "ESC": {
          "stateValue": "ESC",
          "displayValue": "Escuintla"
        },
        "GUA": {
          "stateValue": "GUA",
          "displayValue": "Guatemala"
        },
        "HUE": {
          "stateValue": "HUE",
          "displayValue": "Huehuetenango"
        },
        "IZA": {
          "stateValue": "IZA",
          "displayValue": "Izabal"
        },
        "JAL": {
          "stateValue": "JAL",
          "displayValue": "Jalapa"
        },
        "JUT": {
          "stateValue": "JUT",
          "displayValue": "Jutiapa"
        },
        "PET": {
          "stateValue": "PET",
          "displayValue": "Petén"
        },
        "QUE": {
          "stateValue": "QUE",
          "displayValue": "Quetzaltenango"
        },
        "QUI": {
          "stateValue": "QUI",
          "displayValue": "Quiché"
        },
        "RET": {
          "stateValue": "RET",
          "displayValue": "Retalhuleu"
        },
        "SAC": {
          "stateValue": "SAC",
          "displayValue": "Sacatepéquez"
        },
        "SMA": {
          "stateValue": "SMA",
          "displayValue": "San Marcos"
        },
        "SRO": {
          "stateValue": "SRO",
          "displayValue": "Santa Rosa"
        },
        "SOL": {
          "stateValue": "SOL",
          "displayValue": "Sololá"
        },
        "SUC": {
          "stateValue": "SUC",
          "displayValue": "Suchitepéquez"
        },
        "TOT": {
          "stateValue": "TOT",
          "displayValue": "Totonicapán"
        },
        "ZAC": {
          "stateValue": "ZAC",
          "displayValue": "Zacapa"
        }
      }
    },
    "CH": {
      "countryId": "CH",
      "displayValue": "Switzerland",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "ET": {
      "countryId": "ET",
      "displayValue": "Ethiopia",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "ML": {
      "countryId": "ML",
      "displayValue": "Mali",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "SN": {
      "countryId": "SN",
      "displayValue": "Senegal",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "SX": {
      "countryId": "SX",
      "displayValue": "Sint Maarten (Dutch Part)",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "DK": {
      "countryId": "DK",
      "displayValue": "Denmark",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "BD": {
      "countryId": "BD",
      "displayValue": "Bangladesh",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "states": {}
    },
    "AZ": {
      "countryId": "AZ",
      "displayValue": "Azerbaijan",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(AZ\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "LU": {
      "countryId": "LU",
      "displayValue": "Luxembourg",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "MN": {
      "countryId": "MN",
      "displayValue": "Mongolia",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{6})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "states": {}
    },
    "MG": {
      "countryId": "MG",
      "displayValue": "Madagascar",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{3})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "CY": {
      "countryId": "CY",
      "displayValue": "Cyprus",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "YE": {
      "countryId": "YE",
      "displayValue": "Yemen",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "MW": {
      "countryId": "MW",
      "displayValue": "Malawi",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "PY": {
      "countryId": "PY",
      "displayValue": "Paraguay",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "FI": {
      "countryId": "FI",
      "displayValue": "Finland",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "VE": {
      "countryId": "VE",
      "displayValue": "Venezuela, Bolivarian Republic of",
      "stateDisplayValue": "State",
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4}|\\d{4}(\\s)*[A-Z])$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "states": {}
    },
    "AW": {
      "countryId": "AW",
      "displayValue": "Aruba",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "EG": {
      "countryId": "EG",
      "displayValue": "Egypt",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{province}_{city}_{zip}_{country}_{phone}",
      "states": {
        "SU": {
          "stateValue": "SU",
          "displayValue": "6th of October"
        },
        "SHR": {
          "stateValue": "SHR",
          "displayValue": "Al Sharqia"
        },
        "ALX": {
          "stateValue": "ALX",
          "displayValue": "Alexandria"
        },
        "ASN": {
          "stateValue": "ASN",
          "displayValue": "Aswan"
        },
        "AST": {
          "stateValue": "AST",
          "displayValue": "Asyut"
        },
        "BH": {
          "stateValue": "BH",
          "displayValue": "Beheira"
        },
        "BNS": {
          "stateValue": "BNS",
          "displayValue": "Beni Suef"
        },
        "C": {
          "stateValue": "C",
          "displayValue": "Cairo"
        },
        "DK": {
          "stateValue": "DK",
          "displayValue": "Dakahlia"
        },
        "DT": {
          "stateValue": "DT",
          "displayValue": "Damietta"
        },
        "FYM": {
          "stateValue": "FYM",
          "displayValue": "Faiyum"
        },
        "GH": {
          "stateValue": "GH",
          "displayValue": "Gharbia"
        },
        "GZ": {
          "stateValue": "GZ",
          "displayValue": "Giza"
        },
        "HU": {
          "stateValue": "HU",
          "displayValue": "Helwan"
        },
        "IS": {
          "stateValue": "IS",
          "displayValue": "Ismailia"
        },
        "KFS": {
          "stateValue": "KFS",
          "displayValue": "Kafr el-Sheikh"
        },
        "LX": {
          "stateValue": "LX",
          "displayValue": "Luxor"
        },
        "MT": {
          "stateValue": "MT",
          "displayValue": "Matrouh"
        },
        "MN": {
          "stateValue": "MN",
          "displayValue": "Minya"
        },
        "MNF": {
          "stateValue": "MNF",
          "displayValue": "Monufia"
        },
        "WAD": {
          "stateValue": "WAD",
          "displayValue": "New Valley"
        },
        "SIN": {
          "stateValue": "SIN",
          "displayValue": "North Sinai"
        },
        "PTS": {
          "stateValue": "PTS",
          "displayValue": "Port Said"
        },
        "KB": {
          "stateValue": "KB",
          "displayValue": "Qalyubia"
        },
        "KN": {
          "stateValue": "KN",
          "displayValue": "Qena"
        },
        "BA": {
          "stateValue": "BA",
          "displayValue": "Red Sea"
        },
        "SHG": {
          "stateValue": "SHG",
          "displayValue": "Sohag"
        },
        "JS": {
          "stateValue": "JS",
          "displayValue": "South Sinai"
        },
        "SUZ": {
          "stateValue": "SUZ",
          "displayValue": "Suez"
        }
      }
    },
    "CO": {
      "countryId": "CO",
      "displayValue": "Colombia",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{6})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
      "states": {
        "DC": {
          "stateValue": "DC",
          "displayValue": "Capital District"
        },
        "AMA": {
          "stateValue": "AMA",
          "displayValue": "Amazonas"
        },
        "ANT": {
          "stateValue": "ANT",
          "displayValue": "Antioquia"
        },
        "ARA": {
          "stateValue": "ARA",
          "displayValue": "Arauca"
        },
        "ATL": {
          "stateValue": "ATL",
          "displayValue": "Atlántico"
        },
        "BOL": {
          "stateValue": "BOL",
          "displayValue": "Bolívar"
        },
        "BOY": {
          "stateValue": "BOY",
          "displayValue": "Boyacá"
        },
        "CAL": {
          "stateValue": "CAL",
          "displayValue": "Caldas"
        },
        "CAQ": {
          "stateValue": "CAQ",
          "displayValue": "Caquetá"
        },
        "CAS": {
          "stateValue": "CAS",
          "displayValue": "Casanare"
        },
        "CAU": {
          "stateValue": "CAU",
          "displayValue": "Cauca"
        },
        "CES": {
          "stateValue": "CES",
          "displayValue": "Cesar"
        },
        "CHO": {
          "stateValue": "CHO",
          "displayValue": "Chocó"
        },
        "COR": {
          "stateValue": "COR",
          "displayValue": "Córdoba"
        },
        "CUN": {
          "stateValue": "CUN",
          "displayValue": "Cundinamarca"
        },
        "GUA": {
          "stateValue": "GUA",
          "displayValue": "Guainía"
        },
        "GUV": {
          "stateValue": "GUV",
          "displayValue": "Guaviare"
        },
        "HUI": {
          "stateValue": "HUI",
          "displayValue": "Huila"
        },
        "LAG": {
          "stateValue": "LAG",
          "displayValue": "La Guajira"
        },
        "MAG": {
          "stateValue": "MAG",
          "displayValue": "Magdalena"
        },
        "MET": {
          "stateValue": "MET",
          "displayValue": "Meta"
        },
        "NAR": {
          "stateValue": "NAR",
          "displayValue": "Nariño"
        },
        "NSA": {
          "stateValue": "NSA",
          "displayValue": "Norte de Santander"
        },
        "PUT": {
          "stateValue": "PUT",
          "displayValue": "Putumayo"
        },
        "QUI": {
          "stateValue": "QUI",
          "displayValue": "Quindío"
        },
        "RIS": {
          "stateValue": "RIS",
          "displayValue": "Risaralda"
        },
        "SAP": {
          "stateValue": "SAP",
          "displayValue": "San Andrés & Providencia"
        },
        "SAN": {
          "stateValue": "SAN",
          "displayValue": "Santander"
        },
        "SUC": {
          "stateValue": "SUC",
          "displayValue": "Sucre"
        },
        "TOL": {
          "stateValue": "TOL",
          "displayValue": "Tolima"
        },
        "VAC": {
          "stateValue": "VAC",
          "displayValue": "Valle del Cauca"
        },
        "VAU": {
          "stateValue": "VAU",
          "displayValue": "Vaupés"
        },
        "VID": {
          "stateValue": "VID",
          "displayValue": "Vichada"
        }
      }
    },
    "PS": {
      "countryId": "PS",
      "displayValue": "Palestine, State Of",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "DO": {
      "countryId": "DO",
      "displayValue": "Dominican Republic",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "AE": {
      "countryId": "AE",
      "displayValue": "United Arab Emirates",
      "stateDisplayValue": "Emirate",
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": true,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {province}_{country}_{phone}",
      "states": {
        "AZ": {
          "stateValue": "AZ",
          "displayValue": "Abu Dhabi"
        },
        "AJ": {
          "stateValue": "AJ",
          "displayValue": "Ajman"
        },
        "DU": {
          "stateValue": "DU",
          "displayValue": "Dubai"
        },
        "FU": {
          "stateValue": "FU",
          "displayValue": "Fujairah"
        },
        "RK": {
          "stateValue": "RK",
          "displayValue": "Ras al-Khaimah"
        },
        "SH": {
          "stateValue": "SH",
          "displayValue": "Sharjah"
        },
        "UQ": {
          "stateValue": "UQ",
          "displayValue": "Umm al-Quwain"
        }
      }
    },
    "ID": {
      "countryId": "ID",
      "displayValue": "Indonesia",
      "stateDisplayValue": "Province",
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{province} {zip}_{country}_{phone}",
      "states": {
        "AC": {
          "stateValue": "AC",
          "displayValue": "Aceh"
        },
        "BA": {
          "stateValue": "BA",
          "displayValue": "Bali"
        },
        "BB": {
          "stateValue": "BB",
          "displayValue": "Bangka–Belitung Islands"
        },
        "BT": {
          "stateValue": "BT",
          "displayValue": "Banten"
        },
        "BE": {
          "stateValue": "BE",
          "displayValue": "Bengkulu"
        },
        "GO": {
          "stateValue": "GO",
          "displayValue": "Gorontalo"
        },
        "JK": {
          "stateValue": "JK",
          "displayValue": "Jakarta"
        },
        "JA": {
          "stateValue": "JA",
          "displayValue": "Jambi"
        },
        "JB": {
          "stateValue": "JB",
          "displayValue": "West Java"
        },
        "JT": {
          "stateValue": "JT",
          "displayValue": "Central Java"
        },
        "JI": {
          "stateValue": "JI",
          "displayValue": "East Java"
        },
        "KB": {
          "stateValue": "KB",
          "displayValue": "West Kalimantan"
        },
        "KS": {
          "stateValue": "KS",
          "displayValue": "South Kalimantan"
        },
        "KT": {
          "stateValue": "KT",
          "displayValue": "Central Kalimantan"
        },
        "KI": {
          "stateValue": "KI",
          "displayValue": "East Kalimantan"
        },
        "KU": {
          "stateValue": "KU",
          "displayValue": "North Kalimantan"
        },
        "KR": {
          "stateValue": "KR",
          "displayValue": "Riau Islands"
        },
        "LA": {
          "stateValue": "LA",
          "displayValue": "Lampung"
        },
        "MA": {
          "stateValue": "MA",
          "displayValue": "Maluku"
        },
        "MU": {
          "stateValue": "MU",
          "displayValue": "North Maluku"
        },
        "SU": {
          "stateValue": "SU",
          "displayValue": "North Sumatra"
        },
        "NB": {
          "stateValue": "NB",
          "displayValue": "West Nusa Tenggara"
        },
        "NT": {
          "stateValue": "NT",
          "displayValue": "East Nusa Tenggara"
        },
        "PA": {
          "stateValue": "PA",
          "displayValue": "Papua"
        },
        "PB": {
          "stateValue": "PB",
          "displayValue": "West Papua"
        },
        "RI": {
          "stateValue": "RI",
          "displayValue": "Riau"
        },
        "SS": {
          "stateValue": "SS",
          "displayValue": "South Sumatra"
        },
        "SR": {
          "stateValue": "SR",
          "displayValue": "West Sulawesi"
        },
        "SN": {
          "stateValue": "SN",
          "displayValue": "South Sulawesi"
        },
        "ST": {
          "stateValue": "ST",
          "displayValue": "Central Sulawesi"
        },
        "SG": {
          "stateValue": "SG",
          "displayValue": "Southeast Sulawesi"
        },
        "SA": {
          "stateValue": "SA",
          "displayValue": "North Sulawesi"
        },
        "SB": {
          "stateValue": "SB",
          "displayValue": "West Sumatra"
        },
        "YO": {
          "stateValue": "YO",
          "displayValue": "Yogyakarta"
        }
      }
    },
    "IT": {
      "countryId": "IT",
      "displayValue": "Italy",
      "stateDisplayValue": "Province",
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
      "states": {
        "AG": {
          "stateValue": "AG",
          "displayValue": "Agrigento"
        },
        "AL": {
          "stateValue": "AL",
          "displayValue": "Alessandria"
        },
        "AN": {
          "stateValue": "AN",
          "displayValue": "Ancona"
        },
        "AO": {
          "stateValue": "AO",
          "displayValue": "Aosta"
        },
        "AR": {
          "stateValue": "AR",
          "displayValue": "Arezzo"
        },
        "AP": {
          "stateValue": "AP",
          "displayValue": "Ascoli Piceno"
        },
        "AT": {
          "stateValue": "AT",
          "displayValue": "Asti"
        },
        "AV": {
          "stateValue": "AV",
          "displayValue": "Avellino"
        },
        "BA": {
          "stateValue": "BA",
          "displayValue": "Bari"
        },
        "BT": {
          "stateValue": "BT",
          "displayValue": "Barletta-Andria-Trani"
        },
        "BL": {
          "stateValue": "BL",
          "displayValue": "Belluno"
        },
        "BN": {
          "stateValue": "BN",
          "displayValue": "Benevento"
        },
        "BG": {
          "stateValue": "BG",
          "displayValue": "Bergamo"
        },
        "BI": {
          "stateValue": "BI",
          "displayValue": "Biella"
        },
        "BO": {
          "stateValue": "BO",
          "displayValue": "Bologna"
        },
        "BZ": {
          "stateValue": "BZ",
          "displayValue": "South Tyrol"
        },
        "BS": {
          "stateValue": "BS",
          "displayValue": "Brescia"
        },
        "BR": {
          "stateValue": "BR",
          "displayValue": "Brindisi"
        },
        "CA": {
          "stateValue": "CA",
          "displayValue": "Cagliari"
        },
        "CL": {
          "stateValue": "CL",
          "displayValue": "Caltanissetta"
        },
        "CB": {
          "stateValue": "CB",
          "displayValue": "Campobasso"
        },
        "CI": {
          "stateValue": "CI",
          "displayValue": "Carbonia-Iglesias"
        },
        "CE": {
          "stateValue": "CE",
          "displayValue": "Caserta"
        },
        "CT": {
          "stateValue": "CT",
          "displayValue": "Catania"
        },
        "CZ": {
          "stateValue": "CZ",
          "displayValue": "Catanzaro"
        },
        "CH": {
          "stateValue": "CH",
          "displayValue": "Chieti"
        },
        "CO": {
          "stateValue": "CO",
          "displayValue": "Como"
        },
        "CS": {
          "stateValue": "CS",
          "displayValue": "Cosenza"
        },
        "CR": {
          "stateValue": "CR",
          "displayValue": "Cremona"
        },
        "KR": {
          "stateValue": "KR",
          "displayValue": "Crotone"
        },
        "CN": {
          "stateValue": "CN",
          "displayValue": "Cuneo"
        },
        "EN": {
          "stateValue": "EN",
          "displayValue": "Enna"
        },
        "FM": {
          "stateValue": "FM",
          "displayValue": "Fermo"
        },
        "FE": {
          "stateValue": "FE",
          "displayValue": "Ferrara"
        },
        "FI": {
          "stateValue": "FI",
          "displayValue": "Florence"
        },
        "FG": {
          "stateValue": "FG",
          "displayValue": "Foggia"
        },
        "FC": {
          "stateValue": "FC",
          "displayValue": "Forlì-Cesena"
        },
        "FR": {
          "stateValue": "FR",
          "displayValue": "Frosinone"
        },
        "GE": {
          "stateValue": "GE",
          "displayValue": "Genoa"
        },
        "GO": {
          "stateValue": "GO",
          "displayValue": "Gorizia"
        },
        "GR": {
          "stateValue": "GR",
          "displayValue": "Grosseto"
        },
        "IM": {
          "stateValue": "IM",
          "displayValue": "Imperia"
        },
        "IS": {
          "stateValue": "IS",
          "displayValue": "Isernia"
        },
        "AQ": {
          "stateValue": "AQ",
          "displayValue": "L’Aquila"
        },
        "SP": {
          "stateValue": "SP",
          "displayValue": "La Spezia"
        },
        "LT": {
          "stateValue": "LT",
          "displayValue": "Latina"
        },
        "LE": {
          "stateValue": "LE",
          "displayValue": "Lecce"
        },
        "LC": {
          "stateValue": "LC",
          "displayValue": "Lecco"
        },
        "LI": {
          "stateValue": "LI",
          "displayValue": "Livorno"
        },
        "LO": {
          "stateValue": "LO",
          "displayValue": "Lodi"
        },
        "LU": {
          "stateValue": "LU",
          "displayValue": "Lucca"
        },
        "MC": {
          "stateValue": "MC",
          "displayValue": "Macerata"
        },
        "MN": {
          "stateValue": "MN",
          "displayValue": "Mantua"
        },
        "MS": {
          "stateValue": "MS",
          "displayValue": "Massa and Carrara"
        },
        "MT": {
          "stateValue": "MT",
          "displayValue": "Matera"
        },
        "VS": {
          "stateValue": "VS",
          "displayValue": "Medio Campidano"
        },
        "ME": {
          "stateValue": "ME",
          "displayValue": "Messina"
        },
        "MI": {
          "stateValue": "MI",
          "displayValue": "Milan"
        },
        "MO": {
          "stateValue": "MO",
          "displayValue": "Modena"
        },
        "MB": {
          "stateValue": "MB",
          "displayValue": "Monza and Brianza"
        },
        "NA": {
          "stateValue": "NA",
          "displayValue": "Naples"
        },
        "NO": {
          "stateValue": "NO",
          "displayValue": "Novara"
        },
        "NU": {
          "stateValue": "NU",
          "displayValue": "Nuoro"
        },
        "OG": {
          "stateValue": "OG",
          "displayValue": "Ogliastra"
        },
        "OT": {
          "stateValue": "OT",
          "displayValue": "Olbia-Tempio"
        },
        "OR": {
          "stateValue": "OR",
          "displayValue": "Oristano"
        },
        "PD": {
          "stateValue": "PD",
          "displayValue": "Padua"
        },
        "PA": {
          "stateValue": "PA",
          "displayValue": "Palermo"
        },
        "PR": {
          "stateValue": "PR",
          "displayValue": "Parma"
        },
        "PV": {
          "stateValue": "PV",
          "displayValue": "Pavia"
        },
        "PG": {
          "stateValue": "PG",
          "displayValue": "Perugia"
        },
        "PU": {
          "stateValue": "PU",
          "displayValue": "Pesaro and Urbino"
        },
        "PE": {
          "stateValue": "PE",
          "displayValue": "Pescara"
        },
        "PC": {
          "stateValue": "PC",
          "displayValue": "Piacenza"
        },
        "PI": {
          "stateValue": "PI",
          "displayValue": "Pisa"
        },
        "PT": {
          "stateValue": "PT",
          "displayValue": "Pistoia"
        },
        "PN": {
          "stateValue": "PN",
          "displayValue": "Pordenone"
        },
        "PZ": {
          "stateValue": "PZ",
          "displayValue": "Potenza"
        },
        "PO": {
          "stateValue": "PO",
          "displayValue": "Prato"
        },
        "RG": {
          "stateValue": "RG",
          "displayValue": "Ragusa"
        },
        "RA": {
          "stateValue": "RA",
          "displayValue": "Ravenna"
        },
        "RC": {
          "stateValue": "RC",
          "displayValue": "Reggio Calabria"
        },
        "RE": {
          "stateValue": "RE",
          "displayValue": "Reggio Emilia"
        },
        "RI": {
          "stateValue": "RI",
          "displayValue": "Rieti"
        },
        "RN": {
          "stateValue": "RN",
          "displayValue": "Rimini"
        },
        "RM": {
          "stateValue": "RM",
          "displayValue": "Rome"
        },
        "RO": {
          "stateValue": "RO",
          "displayValue": "Rovigo"
        },
        "SA": {
          "stateValue": "SA",
          "displayValue": "Salerno"
        },
        "SS": {
          "stateValue": "SS",
          "displayValue": "Sassari"
        },
        "SV": {
          "stateValue": "SV",
          "displayValue": "Savona"
        },
        "SI": {
          "stateValue": "SI",
          "displayValue": "Siena"
        },
        "SR": {
          "stateValue": "SR",
          "displayValue": "Syracuse"
        },
        "SO": {
          "stateValue": "SO",
          "displayValue": "Sondrio"
        },
        "TA": {
          "stateValue": "TA",
          "displayValue": "Taranto"
        },
        "TE": {
          "stateValue": "TE",
          "displayValue": "Teramo"
        },
        "TR": {
          "stateValue": "TR",
          "displayValue": "Terni"
        },
        "TO": {
          "stateValue": "TO",
          "displayValue": "Turin"
        },
        "TP": {
          "stateValue": "TP",
          "displayValue": "Trapani"
        },
        "TN": {
          "stateValue": "TN",
          "displayValue": "Trentino"
        },
        "TV": {
          "stateValue": "TV",
          "displayValue": "Treviso"
        },
        "TS": {
          "stateValue": "TS",
          "displayValue": "Trieste"
        },
        "UD": {
          "stateValue": "UD",
          "displayValue": "Udine"
        },
        "VA": {
          "stateValue": "VA",
          "displayValue": "Varese"
        },
        "VE": {
          "stateValue": "VE",
          "displayValue": "Venice"
        },
        "VB": {
          "stateValue": "VB",
          "displayValue": "Verbano-Cusio-Ossola"
        },
        "VC": {
          "stateValue": "VC",
          "displayValue": "Vercelli"
        },
        "VR": {
          "stateValue": "VR",
          "displayValue": "Verona"
        },
        "VV": {
          "stateValue": "VV",
          "displayValue": "Vibo Valentia"
        },
        "VI": {
          "stateValue": "VI",
          "displayValue": "Vicenza"
        },
        "VT": {
          "stateValue": "VT",
          "displayValue": "Viterbo"
        }
      }
    },
    "BM": {
      "countryId": "BM",
      "displayValue": "Bermuda",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^([A-Z]{2}(\\s)*\\d{2}|[A-Z]{2}(\\s)*[A-Z]{2})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "states": {}
    },
    "CZ": {
      "countryId": "CZ",
      "displayValue": "Czech Republic",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{3}(\\s)*\\d{2}|\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "TH": {
      "countryId": "TH",
      "displayValue": "Thailand",
      "stateDisplayValue": null,
      "cityDisplayValue": "District",
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{province} {zip}_{country}_{phone}",
      "states": {
        "TH-37": {
          "stateValue": "TH-37",
          "displayValue": "Amnat Charoen"
        },
        "TH-15": {
          "stateValue": "TH-15",
          "displayValue": "Ang Thong"
        },
        "TH-10": {
          "stateValue": "TH-10",
          "displayValue": "Bangkok"
        },
        "TH-38": {
          "stateValue": "TH-38",
          "displayValue": "Bueng Kan"
        },
        "TH-31": {
          "stateValue": "TH-31",
          "displayValue": "Buri Ram"
        },
        "TH-24": {
          "stateValue": "TH-24",
          "displayValue": "Chachoengsao"
        },
        "TH-18": {
          "stateValue": "TH-18",
          "displayValue": "Chai Nat"
        },
        "TH-36": {
          "stateValue": "TH-36",
          "displayValue": "Chaiyaphum"
        },
        "TH-22": {
          "stateValue": "TH-22",
          "displayValue": "Chanthaburi"
        },
        "TH-50": {
          "stateValue": "TH-50",
          "displayValue": "Chiang Mai"
        },
        "TH-57": {
          "stateValue": "TH-57",
          "displayValue": "Chiang Rai"
        },
        "TH-20": {
          "stateValue": "TH-20",
          "displayValue": "Chon Buri"
        },
        "TH-86": {
          "stateValue": "TH-86",
          "displayValue": "Chumphon"
        },
        "TH-46": {
          "stateValue": "TH-46",
          "displayValue": "Kalasin"
        },
        "TH-62": {
          "stateValue": "TH-62",
          "displayValue": "Kamphaeng Phet"
        },
        "TH-71": {
          "stateValue": "TH-71",
          "displayValue": "Kanchanaburi"
        },
        "TH-40": {
          "stateValue": "TH-40",
          "displayValue": "Khon Kaen"
        },
        "TH-81": {
          "stateValue": "TH-81",
          "displayValue": "Krabi"
        },
        "TH-52": {
          "stateValue": "TH-52",
          "displayValue": "Lampang"
        },
        "TH-51": {
          "stateValue": "TH-51",
          "displayValue": "Lamphun"
        },
        "TH-42": {
          "stateValue": "TH-42",
          "displayValue": "Loei"
        },
        "TH-16": {
          "stateValue": "TH-16",
          "displayValue": "Lopburi"
        },
        "TH-58": {
          "stateValue": "TH-58",
          "displayValue": "Mae Hong Son"
        },
        "TH-44": {
          "stateValue": "TH-44",
          "displayValue": "Maha Sarakham"
        },
        "TH-49": {
          "stateValue": "TH-49",
          "displayValue": "Mukdahan"
        },
        "TH-26": {
          "stateValue": "TH-26",
          "displayValue": "Nakhon Nayok"
        },
        "TH-73": {
          "stateValue": "TH-73",
          "displayValue": "Nakhon Pathom"
        },
        "TH-48": {
          "stateValue": "TH-48",
          "displayValue": "Nakhon Phanom"
        },
        "TH-30": {
          "stateValue": "TH-30",
          "displayValue": "Nakhon Ratchasima"
        },
        "TH-60": {
          "stateValue": "TH-60",
          "displayValue": "Nakhon Sawan"
        },
        "TH-80": {
          "stateValue": "TH-80",
          "displayValue": "Nakhon Si Thammarat"
        },
        "TH-55": {
          "stateValue": "TH-55",
          "displayValue": "Nan"
        },
        "TH-96": {
          "stateValue": "TH-96",
          "displayValue": "Narathiwat"
        },
        "TH-39": {
          "stateValue": "TH-39",
          "displayValue": "Nong Bua Lam Phu"
        },
        "TH-43": {
          "stateValue": "TH-43",
          "displayValue": "Nong Khai"
        },
        "TH-12": {
          "stateValue": "TH-12",
          "displayValue": "Nonthaburi"
        },
        "TH-13": {
          "stateValue": "TH-13",
          "displayValue": "Pathum Thani"
        },
        "TH-94": {
          "stateValue": "TH-94",
          "displayValue": "Pattani"
        },
        "TH-S": {
          "stateValue": "TH-S",
          "displayValue": "Pattaya"
        },
        "TH-82": {
          "stateValue": "TH-82",
          "displayValue": "Phang Nga"
        },
        "TH-93": {
          "stateValue": "TH-93",
          "displayValue": "Phatthalung"
        },
        "TH-56": {
          "stateValue": "TH-56",
          "displayValue": "Phayao"
        },
        "TH-67": {
          "stateValue": "TH-67",
          "displayValue": "Phetchabun"
        },
        "TH-76": {
          "stateValue": "TH-76",
          "displayValue": "Phetchaburi"
        },
        "TH-66": {
          "stateValue": "TH-66",
          "displayValue": "Phichit"
        },
        "TH-65": {
          "stateValue": "TH-65",
          "displayValue": "Phitsanulok"
        },
        "TH-14": {
          "stateValue": "TH-14",
          "displayValue": "Phra Nakhon Si Ayutthaya"
        },
        "TH-54": {
          "stateValue": "TH-54",
          "displayValue": "Phrae"
        },
        "TH-83": {
          "stateValue": "TH-83",
          "displayValue": "Phuket"
        },
        "TH-25": {
          "stateValue": "TH-25",
          "displayValue": "Prachin Buri"
        },
        "TH-77": {
          "stateValue": "TH-77",
          "displayValue": "Prachuap Khiri Khan"
        },
        "TH-85": {
          "stateValue": "TH-85",
          "displayValue": "Ranong"
        },
        "TH-70": {
          "stateValue": "TH-70",
          "displayValue": "Ratchaburi"
        },
        "TH-21": {
          "stateValue": "TH-21",
          "displayValue": "Rayong"
        },
        "TH-45": {
          "stateValue": "TH-45",
          "displayValue": "Roi Et"
        },
        "TH-27": {
          "stateValue": "TH-27",
          "displayValue": "Sa Kaeo"
        },
        "TH-47": {
          "stateValue": "TH-47",
          "displayValue": "Sakon Nakhon"
        },
        "TH-11": {
          "stateValue": "TH-11",
          "displayValue": "Samut Prakan"
        },
        "TH-74": {
          "stateValue": "TH-74",
          "displayValue": "Samut Sakhon"
        },
        "TH-75": {
          "stateValue": "TH-75",
          "displayValue": "Samut Songkhram"
        },
        "TH-19": {
          "stateValue": "TH-19",
          "displayValue": "Saraburi"
        },
        "TH-91": {
          "stateValue": "TH-91",
          "displayValue": "Satun"
        },
        "TH-17": {
          "stateValue": "TH-17",
          "displayValue": "Sing Buri"
        },
        "TH-33": {
          "stateValue": "TH-33",
          "displayValue": "Si Sa Ket"
        },
        "TH-90": {
          "stateValue": "TH-90",
          "displayValue": "Songkhla"
        },
        "TH-64": {
          "stateValue": "TH-64",
          "displayValue": "Sukhothai"
        },
        "TH-72": {
          "stateValue": "TH-72",
          "displayValue": "Suphanburi"
        },
        "TH-84": {
          "stateValue": "TH-84",
          "displayValue": "Surat Thani"
        },
        "TH-32": {
          "stateValue": "TH-32",
          "displayValue": "Surin"
        },
        "TH-63": {
          "stateValue": "TH-63",
          "displayValue": "Tak"
        },
        "TH-92": {
          "stateValue": "TH-92",
          "displayValue": "Trang"
        },
        "TH-23": {
          "stateValue": "TH-23",
          "displayValue": "Trat"
        },
        "TH-34": {
          "stateValue": "TH-34",
          "displayValue": "Ubon Ratchathani"
        },
        "TH-41": {
          "stateValue": "TH-41",
          "displayValue": "Udon Thani"
        },
        "TH-61": {
          "stateValue": "TH-61",
          "displayValue": "Uthai Thani"
        },
        "TH-53": {
          "stateValue": "TH-53",
          "displayValue": "Uttaradit"
        },
        "TH-95": {
          "stateValue": "TH-95",
          "displayValue": "Yala"
        },
        "TH-35": {
          "stateValue": "TH-35",
          "displayValue": "Yasothon"
        }
      }
    },
    "VU": {
      "countryId": "VU",
      "displayValue": "Vanuatu",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "KE": {
      "countryId": "KE",
      "displayValue": "Kenya",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "states": {}
    },
    "IS": {
      "countryId": "IS",
      "displayValue": "Iceland",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{3})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "GR": {
      "countryId": "GR",
      "displayValue": "Greece",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{3}\\d{2})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "MQ": {
      "countryId": "MQ",
      "displayValue": "Martinique",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "QA": {
      "countryId": "QA",
      "displayValue": "Qatar",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "EE": {
      "countryId": "EE",
      "displayValue": "Estonia",
      "stateDisplayValue": "Province",
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "AG": {
      "countryId": "AG",
      "displayValue": "Antigua and Barbuda",
      "stateDisplayValue": "Parish or Dependency",
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "BT": {
      "countryId": "BT",
      "displayValue": "Bhutan",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "ES": {
      "countryId": "ES",
      "displayValue": "Spain",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{province}_{country}_{phone}",
      "states": {
        "C": {
          "stateValue": "C",
          "displayValue": "A Coruña"
        },
        "VI": {
          "stateValue": "VI",
          "displayValue": "Álava"
        },
        "AB": {
          "stateValue": "AB",
          "displayValue": "Albacete"
        },
        "A": {
          "stateValue": "A",
          "displayValue": "Alicante"
        },
        "AL": {
          "stateValue": "AL",
          "displayValue": "Almería"
        },
        "O": {
          "stateValue": "O",
          "displayValue": "Asturias Province"
        },
        "AV": {
          "stateValue": "AV",
          "displayValue": "Ávila"
        },
        "BA": {
          "stateValue": "BA",
          "displayValue": "Badajoz"
        },
        "PM": {
          "stateValue": "PM",
          "displayValue": "Balears Province"
        },
        "B": {
          "stateValue": "B",
          "displayValue": "Barcelona"
        },
        "BU": {
          "stateValue": "BU",
          "displayValue": "Burgos"
        },
        "CC": {
          "stateValue": "CC",
          "displayValue": "Cáceres"
        },
        "CA": {
          "stateValue": "CA",
          "displayValue": "Cádiz"
        },
        "S": {
          "stateValue": "S",
          "displayValue": "Cantabria Province"
        },
        "CS": {
          "stateValue": "CS",
          "displayValue": "Castellón"
        },
        "CE": {
          "stateValue": "CE",
          "displayValue": "Ceuta"
        },
        "CR": {
          "stateValue": "CR",
          "displayValue": "Ciudad Real"
        },
        "CO": {
          "stateValue": "CO",
          "displayValue": "Córdoba"
        },
        "CU": {
          "stateValue": "CU",
          "displayValue": "Cuenca"
        },
        "GI": {
          "stateValue": "GI",
          "displayValue": "Girona"
        },
        "GR": {
          "stateValue": "GR",
          "displayValue": "Granada"
        },
        "GU": {
          "stateValue": "GU",
          "displayValue": "Guadalajara"
        },
        "SS": {
          "stateValue": "SS",
          "displayValue": "Gipuzkoa"
        },
        "H": {
          "stateValue": "H",
          "displayValue": "Huelva"
        },
        "HU": {
          "stateValue": "HU",
          "displayValue": "Huesca"
        },
        "J": {
          "stateValue": "J",
          "displayValue": "Jaén"
        },
        "LO": {
          "stateValue": "LO",
          "displayValue": "La Rioja Province"
        },
        "GC": {
          "stateValue": "GC",
          "displayValue": "Las Palmas"
        },
        "LE": {
          "stateValue": "LE",
          "displayValue": "León"
        },
        "L": {
          "stateValue": "L",
          "displayValue": "Lleida"
        },
        "LU": {
          "stateValue": "LU",
          "displayValue": "Lugo"
        },
        "M": {
          "stateValue": "M",
          "displayValue": "Madrid Province"
        },
        "MA": {
          "stateValue": "MA",
          "displayValue": "Málaga"
        },
        "ML": {
          "stateValue": "ML",
          "displayValue": "Melilla"
        },
        "MU": {
          "stateValue": "MU",
          "displayValue": "Murcia"
        },
        "NA": {
          "stateValue": "NA",
          "displayValue": "Navarra"
        },
        "OR": {
          "stateValue": "OR",
          "displayValue": "Ourense"
        },
        "P": {
          "stateValue": "P",
          "displayValue": "Palencia"
        },
        "PO": {
          "stateValue": "PO",
          "displayValue": "Pontevedra"
        },
        "SA": {
          "stateValue": "SA",
          "displayValue": "Salamanca"
        },
        "TF": {
          "stateValue": "TF",
          "displayValue": "Santa Cruz de Tenerife"
        },
        "SG": {
          "stateValue": "SG",
          "displayValue": "Segovia"
        },
        "SE": {
          "stateValue": "SE",
          "displayValue": "Seville"
        },
        "SO": {
          "stateValue": "SO",
          "displayValue": "Soria"
        },
        "T": {
          "stateValue": "T",
          "displayValue": "Tarragona"
        },
        "TE": {
          "stateValue": "TE",
          "displayValue": "Teruel"
        },
        "TO": {
          "stateValue": "TO",
          "displayValue": "Toledo"
        },
        "V": {
          "stateValue": "V",
          "displayValue": "Valencia"
        },
        "VA": {
          "stateValue": "VA",
          "displayValue": "Valladolid"
        },
        "BI": {
          "stateValue": "BI",
          "displayValue": "Biscay"
        },
        "ZA": {
          "stateValue": "ZA",
          "displayValue": "Zamora"
        },
        "Z": {
          "stateValue": "Z",
          "displayValue": "Zaragoza"
        }
      }
    },
    "AI": {
      "countryId": "AI",
      "displayValue": "Anguilla",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "NO": {
      "countryId": "NO",
      "displayValue": "Norway",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "CN": {
      "countryId": "CN",
      "displayValue": "China",
      "stateDisplayValue": "Province",
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{6})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1} {address2} {city}_{zip} {province}_{country}_{phone}",
      "states": {
        "AH": {
          "stateValue": "AH",
          "displayValue": "Anhui"
        },
        "BJ": {
          "stateValue": "BJ",
          "displayValue": "Beijing"
        },
        "CQ": {
          "stateValue": "CQ",
          "displayValue": "Chongqing"
        },
        "FJ": {
          "stateValue": "FJ",
          "displayValue": "Fujian"
        },
        "GS": {
          "stateValue": "GS",
          "displayValue": "Gansu"
        },
        "GD": {
          "stateValue": "GD",
          "displayValue": "Guangdong"
        },
        "GX": {
          "stateValue": "GX",
          "displayValue": "Guangxi"
        },
        "GZ": {
          "stateValue": "GZ",
          "displayValue": "Guizhou"
        },
        "HI": {
          "stateValue": "HI",
          "displayValue": "Hainan"
        },
        "HE": {
          "stateValue": "HE",
          "displayValue": "Hebei"
        },
        "HL": {
          "stateValue": "HL",
          "displayValue": "Heilongjiang"
        },
        "HA": {
          "stateValue": "HA",
          "displayValue": "Henan"
        },
        "HB": {
          "stateValue": "HB",
          "displayValue": "Hubei"
        },
        "HN": {
          "stateValue": "HN",
          "displayValue": "Hunan"
        },
        "NM": {
          "stateValue": "NM",
          "displayValue": "Inner Mongolia"
        },
        "JS": {
          "stateValue": "JS",
          "displayValue": "Jiangsu"
        },
        "JX": {
          "stateValue": "JX",
          "displayValue": "Jiangxi"
        },
        "JL": {
          "stateValue": "JL",
          "displayValue": "Jilin"
        },
        "LN": {
          "stateValue": "LN",
          "displayValue": "Liaoning"
        },
        "NX": {
          "stateValue": "NX",
          "displayValue": "Ningxia"
        },
        "QH": {
          "stateValue": "QH",
          "displayValue": "Qinghai"
        },
        "SN": {
          "stateValue": "SN",
          "displayValue": "Shaanxi"
        },
        "SD": {
          "stateValue": "SD",
          "displayValue": "Shandong"
        },
        "SH": {
          "stateValue": "SH",
          "displayValue": "Shanghai"
        },
        "SX": {
          "stateValue": "SX",
          "displayValue": "Shanxi"
        },
        "SC": {
          "stateValue": "SC",
          "displayValue": "Sichuan"
        },
        "TJ": {
          "stateValue": "TJ",
          "displayValue": "Tianjin"
        },
        "XJ": {
          "stateValue": "XJ",
          "displayValue": "Xinjiang"
        },
        "YZ": {
          "stateValue": "YZ",
          "displayValue": "Tibet"
        },
        "YN": {
          "stateValue": "YN",
          "displayValue": "Yunnan"
        },
        "ZJ": {
          "stateValue": "ZJ",
          "displayValue": "Zhejiang"
        }
      }
    },
    "TC": {
      "countryId": "TC",
      "displayValue": "Turks and Caicos Islands",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "states": {}
    },
    "MX": {
      "countryId": "MX",
      "displayValue": "Mexico",
      "stateDisplayValue": "State",
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
      "states": {
        "AGS": {
          "stateValue": "AGS",
          "displayValue": "Aguascalientes"
        },
        "BC": {
          "stateValue": "BC",
          "displayValue": "Baja California"
        },
        "BCS": {
          "stateValue": "BCS",
          "displayValue": "Baja California Sur"
        },
        "CAMP": {
          "stateValue": "CAMP",
          "displayValue": "Campeche"
        },
        "CHIS": {
          "stateValue": "CHIS",
          "displayValue": "Chiapas"
        },
        "CHIH": {
          "stateValue": "CHIH",
          "displayValue": "Chihuahua"
        },
        "DF": {
          "stateValue": "DF",
          "displayValue": "Ciudad de Mexico"
        },
        "COAH": {
          "stateValue": "COAH",
          "displayValue": "Coahuila"
        },
        "COL": {
          "stateValue": "COL",
          "displayValue": "Colima"
        },
        "DGO": {
          "stateValue": "DGO",
          "displayValue": "Durango"
        },
        "GTO": {
          "stateValue": "GTO",
          "displayValue": "Guanajuato"
        },
        "GRO": {
          "stateValue": "GRO",
          "displayValue": "Guerrero"
        },
        "HGO": {
          "stateValue": "HGO",
          "displayValue": "Hidalgo"
        },
        "JAL": {
          "stateValue": "JAL",
          "displayValue": "Jalisco"
        },
        "MEX": {
          "stateValue": "MEX",
          "displayValue": "Mexico State"
        },
        "MICH": {
          "stateValue": "MICH",
          "displayValue": "Michoacán"
        },
        "MOR": {
          "stateValue": "MOR",
          "displayValue": "Morelos"
        },
        "NAY": {
          "stateValue": "NAY",
          "displayValue": "Nayarit"
        },
        "NL": {
          "stateValue": "NL",
          "displayValue": "Nuevo León"
        },
        "OAX": {
          "stateValue": "OAX",
          "displayValue": "Oaxaca"
        },
        "PUE": {
          "stateValue": "PUE",
          "displayValue": "Puebla"
        },
        "QRO": {
          "stateValue": "QRO",
          "displayValue": "Querétaro"
        },
        "Q ROO": {
          "stateValue": "Q ROO",
          "displayValue": "Quintana Roo"
        },
        "SLP": {
          "stateValue": "SLP",
          "displayValue": "San Luis Potosí"
        },
        "SIN": {
          "stateValue": "SIN",
          "displayValue": "Sinaloa"
        },
        "SON": {
          "stateValue": "SON",
          "displayValue": "Sonora"
        },
        "TAB": {
          "stateValue": "TAB",
          "displayValue": "Tabasco"
        },
        "TAMPS": {
          "stateValue": "TAMPS",
          "displayValue": "Tamaulipas"
        },
        "TLAX": {
          "stateValue": "TLAX",
          "displayValue": "Tlaxcala"
        },
        "VER": {
          "stateValue": "VER",
          "displayValue": "Veracruz"
        },
        "YUC": {
          "stateValue": "YUC",
          "displayValue": "Yucatán"
        },
        "ZAC": {
          "stateValue": "ZAC",
          "displayValue": "Zacatecas"
        }
      }
    },
    "BG": {
      "countryId": "BG",
      "displayValue": "Bulgaria",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "CG": {
      "countryId": "CG",
      "displayValue": "Congo",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "BZ": {
      "countryId": "BZ",
      "displayValue": "Belize",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "SZ": {
      "countryId": "SZ",
      "displayValue": "Swaziland",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^([A-Z]\\d{3})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "states": {}
    },
    "TW": {
      "countryId": "TW",
      "displayValue": "Taiwan",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{3}|\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "DE": {
      "countryId": "DE",
      "displayValue": "Germany",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "OM": {
      "countryId": "OM",
      "displayValue": "Oman",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{3})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip}_{city}_{country}_{phone}",
      "states": {}
    },
    "CD": {
      "countryId": "CD",
      "displayValue": "Congo, The Democratic Republic of the",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "LR": {
      "countryId": "LR",
      "displayValue": "Liberia",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{3})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "MZ": {
      "countryId": "MZ",
      "displayValue": "Mozambique",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "PK": {
      "countryId": "PK",
      "displayValue": "Pakistan",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "states": {}
    },
    "SR": {
      "countryId": "SR",
      "displayValue": "Suriname",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "UZ": {
      "countryId": "UZ",
      "displayValue": "Uzbekistan",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "states": {}
    },
    "ER": {
      "countryId": "ER",
      "displayValue": "Eritrea",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "WF": {
      "countryId": "WF",
      "displayValue": "Wallis and Futuna",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "AT": {
      "countryId": "AT",
      "displayValue": "Austria",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "ME": {
      "countryId": "ME",
      "displayValue": "Montenegro",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "MS": {
      "countryId": "MS",
      "displayValue": "Montserrat",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "PG": {
      "countryId": "PG",
      "displayValue": "Papua and New Guinea",
      "stateDisplayValue": "Province",
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{3})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "states": {}
    },
    "KG": {
      "countryId": "KG",
      "displayValue": "Kyrgyzstan",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{6})$",
      "displayFormat": "{zip} {city}_{address2}_{address1}_{company}_{firstName} {lastName}_{country}_{phone}",
      "states": {}
    },
    "DJ": {
      "countryId": "DJ",
      "displayValue": "Djibouti",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "LS": {
      "countryId": "LS",
      "displayValue": "Lesotho",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{3})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "states": {}
    },
    "TT": {
      "countryId": "TT",
      "displayValue": "Trinidad And Tobago",
      "stateDisplayValue": "County",
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "US": {
      "countryId": "US",
      "displayValue": "United States",
      "stateDisplayValue": "State",
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{5}|\\d{5}-\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {province} {zip}_{country}_{phone}",
      "states": {
        "AL": {
          "stateValue": "AL",
          "displayValue": "Alabama"
        },
        "AK": {
          "stateValue": "AK",
          "displayValue": "Alaska"
        },
        "AS": {
          "stateValue": "AS",
          "displayValue": "American Samoa"
        },
        "AZ": {
          "stateValue": "AZ",
          "displayValue": "Arizona"
        },
        "AR": {
          "stateValue": "AR",
          "displayValue": "Arkansas"
        },
        "CA": {
          "stateValue": "CA",
          "displayValue": "California"
        },
        "CO": {
          "stateValue": "CO",
          "displayValue": "Colorado"
        },
        "CT": {
          "stateValue": "CT",
          "displayValue": "Connecticut"
        },
        "DE": {
          "stateValue": "DE",
          "displayValue": "Delaware"
        },
        "DC": {
          "stateValue": "DC",
          "displayValue": "Washington DC"
        },
        "FM": {
          "stateValue": "FM",
          "displayValue": "Micronesia"
        },
        "FL": {
          "stateValue": "FL",
          "displayValue": "Florida"
        },
        "GA": {
          "stateValue": "GA",
          "displayValue": "Georgia"
        },
        "GU": {
          "stateValue": "GU",
          "displayValue": "Guam"
        },
        "HI": {
          "stateValue": "HI",
          "displayValue": "Hawaii"
        },
        "ID": {
          "stateValue": "ID",
          "displayValue": "Idaho"
        },
        "IL": {
          "stateValue": "IL",
          "displayValue": "Illinois"
        },
        "IN": {
          "stateValue": "IN",
          "displayValue": "Indiana"
        },
        "IA": {
          "stateValue": "IA",
          "displayValue": "Iowa"
        },
        "KS": {
          "stateValue": "KS",
          "displayValue": "Kansas"
        },
        "KY": {
          "stateValue": "KY",
          "displayValue": "Kentucky"
        },
        "LA": {
          "stateValue": "LA",
          "displayValue": "Louisiana"
        },
        "ME": {
          "stateValue": "ME",
          "displayValue": "Maine"
        },
        "MH": {
          "stateValue": "MH",
          "displayValue": "Marshall Islands"
        },
        "MD": {
          "stateValue": "MD",
          "displayValue": "Maryland"
        },
        "MA": {
          "stateValue": "MA",
          "displayValue": "Massachusetts"
        },
        "MI": {
          "stateValue": "MI",
          "displayValue": "Michigan"
        },
        "MN": {
          "stateValue": "MN",
          "displayValue": "Minnesota"
        },
        "MS": {
          "stateValue": "MS",
          "displayValue": "Mississippi"
        },
        "MO": {
          "stateValue": "MO",
          "displayValue": "Missouri"
        },
        "MT": {
          "stateValue": "MT",
          "displayValue": "Montana"
        },
        "NE": {
          "stateValue": "NE",
          "displayValue": "Nebraska"
        },
        "NV": {
          "stateValue": "NV",
          "displayValue": "Nevada"
        },
        "NH": {
          "stateValue": "NH",
          "displayValue": "New Hampshire"
        },
        "NJ": {
          "stateValue": "NJ",
          "displayValue": "New Jersey"
        },
        "NM": {
          "stateValue": "NM",
          "displayValue": "New Mexico"
        },
        "NY": {
          "stateValue": "NY",
          "displayValue": "New York"
        },
        "NC": {
          "stateValue": "NC",
          "displayValue": "North Carolina"
        },
        "ND": {
          "stateValue": "ND",
          "displayValue": "North Dakota"
        },
        "MP": {
          "stateValue": "MP",
          "displayValue": "Northern Mariana Islands"
        },
        "OH": {
          "stateValue": "OH",
          "displayValue": "Ohio"
        },
        "OK": {
          "stateValue": "OK",
          "displayValue": "Oklahoma"
        },
        "OR": {
          "stateValue": "OR",
          "displayValue": "Oregon"
        },
        "PW": {
          "stateValue": "PW",
          "displayValue": "Palau"
        },
        "PA": {
          "stateValue": "PA",
          "displayValue": "Pennsylvania"
        },
        "PR": {
          "stateValue": "PR",
          "displayValue": "Puerto Rico"
        },
        "RI": {
          "stateValue": "RI",
          "displayValue": "Rhode Island"
        },
        "SC": {
          "stateValue": "SC",
          "displayValue": "South Carolina"
        },
        "SD": {
          "stateValue": "SD",
          "displayValue": "South Dakota"
        },
        "TN": {
          "stateValue": "TN",
          "displayValue": "Tennessee"
        },
        "TX": {
          "stateValue": "TX",
          "displayValue": "Texas"
        },
        "UT": {
          "stateValue": "UT",
          "displayValue": "Utah"
        },
        "VT": {
          "stateValue": "VT",
          "displayValue": "Vermont"
        },
        "VI": {
          "stateValue": "VI",
          "displayValue": "U.S. Virgin Islands"
        },
        "VA": {
          "stateValue": "VA",
          "displayValue": "Virginia"
        },
        "WA": {
          "stateValue": "WA",
          "displayValue": "Washington"
        },
        "WV": {
          "stateValue": "WV",
          "displayValue": "West Virginia"
        },
        "WI": {
          "stateValue": "WI",
          "displayValue": "Wisconsin"
        },
        "WY": {
          "stateValue": "WY",
          "displayValue": "Wyoming"
        },
        "AA": {
          "stateValue": "AA",
          "displayValue": "Armed Forces Americas"
        },
        "AE": {
          "stateValue": "AE",
          "displayValue": "Armed Forces Europe"
        },
        "AP": {
          "stateValue": "AP",
          "displayValue": "Armed Forces Pacific"
        }
      }
    },
    "SV": {
      "countryId": "SV",
      "displayValue": "El Salvador",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "VG": {
      "countryId": "VG",
      "displayValue": "Virgin Islands, British",
      "stateDisplayValue": "Island",
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(VG\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "states": {}
    },
    "NC": {
      "countryId": "NC",
      "displayValue": "New Caledonia",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "CM": {
      "countryId": "CM",
      "displayValue": "Cameroon",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "UG": {
      "countryId": "UG",
      "displayValue": "Uganda",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "GF": {
      "countryId": "GF",
      "displayValue": "French Guiana",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "GE": {
      "countryId": "GE",
      "displayValue": "Georgia, Republic Of",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "JP": {
      "countryId": "JP",
      "displayValue": "Japan",
      "stateDisplayValue": "Prefecture",
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{3}-\\d{4})$",
      "displayFormat": "{country} 〒{zip}_{province} {city}_{address1}_{address2}_{company}_{lastName} {firstName}様_{phone}",
      "states": {
        "JP-01": {
          "stateValue": "JP-01",
          "displayValue": "Hokkaido"
        },
        "JP-02": {
          "stateValue": "JP-02",
          "displayValue": "Aomori"
        },
        "JP-03": {
          "stateValue": "JP-03",
          "displayValue": "Iwate"
        },
        "JP-04": {
          "stateValue": "JP-04",
          "displayValue": "Miyagi"
        },
        "JP-05": {
          "stateValue": "JP-05",
          "displayValue": "Akita"
        },
        "JP-06": {
          "stateValue": "JP-06",
          "displayValue": "Yamagata"
        },
        "JP-07": {
          "stateValue": "JP-07",
          "displayValue": "Fukushima"
        },
        "JP-08": {
          "stateValue": "JP-08",
          "displayValue": "Ibaraki"
        },
        "JP-09": {
          "stateValue": "JP-09",
          "displayValue": "Tochigi"
        },
        "JP-10": {
          "stateValue": "JP-10",
          "displayValue": "Gunma"
        },
        "JP-11": {
          "stateValue": "JP-11",
          "displayValue": "Saitama"
        },
        "JP-12": {
          "stateValue": "JP-12",
          "displayValue": "Chiba"
        },
        "JP-13": {
          "stateValue": "JP-13",
          "displayValue": "Tokyo"
        },
        "JP-14": {
          "stateValue": "JP-14",
          "displayValue": "Kanagawa"
        },
        "JP-15": {
          "stateValue": "JP-15",
          "displayValue": "Niigata"
        },
        "JP-16": {
          "stateValue": "JP-16",
          "displayValue": "Toyama"
        },
        "JP-17": {
          "stateValue": "JP-17",
          "displayValue": "Ishikawa"
        },
        "JP-18": {
          "stateValue": "JP-18",
          "displayValue": "Fukui"
        },
        "JP-19": {
          "stateValue": "JP-19",
          "displayValue": "Yamanashi"
        },
        "JP-20": {
          "stateValue": "JP-20",
          "displayValue": "Nagano"
        },
        "JP-21": {
          "stateValue": "JP-21",
          "displayValue": "Gifu"
        },
        "JP-22": {
          "stateValue": "JP-22",
          "displayValue": "Shizuoka"
        },
        "JP-23": {
          "stateValue": "JP-23",
          "displayValue": "Aichi"
        },
        "JP-24": {
          "stateValue": "JP-24",
          "displayValue": "Mie"
        },
        "JP-25": {
          "stateValue": "JP-25",
          "displayValue": "Shiga"
        },
        "JP-26": {
          "stateValue": "JP-26",
          "displayValue": "Kyoto"
        },
        "JP-27": {
          "stateValue": "JP-27",
          "displayValue": "Osaka"
        },
        "JP-28": {
          "stateValue": "JP-28",
          "displayValue": "Hyogo"
        },
        "JP-29": {
          "stateValue": "JP-29",
          "displayValue": "Nara"
        },
        "JP-30": {
          "stateValue": "JP-30",
          "displayValue": "Wakayama"
        },
        "JP-31": {
          "stateValue": "JP-31",
          "displayValue": "Tottori"
        },
        "JP-32": {
          "stateValue": "JP-32",
          "displayValue": "Shimane"
        },
        "JP-33": {
          "stateValue": "JP-33",
          "displayValue": "Okayama"
        },
        "JP-34": {
          "stateValue": "JP-34",
          "displayValue": "Hiroshima"
        },
        "JP-35": {
          "stateValue": "JP-35",
          "displayValue": "Yamaguchi"
        },
        "JP-36": {
          "stateValue": "JP-36",
          "displayValue": "Tokushima"
        },
        "JP-37": {
          "stateValue": "JP-37",
          "displayValue": "Kagawa"
        },
        "JP-38": {
          "stateValue": "JP-38",
          "displayValue": "Ehime"
        },
        "JP-39": {
          "stateValue": "JP-39",
          "displayValue": "Kochi"
        },
        "JP-40": {
          "stateValue": "JP-40",
          "displayValue": "Fukuoka"
        },
        "JP-41": {
          "stateValue": "JP-41",
          "displayValue": "Saga"
        },
        "JP-42": {
          "stateValue": "JP-42",
          "displayValue": "Nagasaki"
        },
        "JP-43": {
          "stateValue": "JP-43",
          "displayValue": "Kumamoto"
        },
        "JP-44": {
          "stateValue": "JP-44",
          "displayValue": "Oita"
        },
        "JP-45": {
          "stateValue": "JP-45",
          "displayValue": "Miyazaki"
        },
        "JP-46": {
          "stateValue": "JP-46",
          "displayValue": "Kagoshima"
        },
        "JP-47": {
          "stateValue": "JP-47",
          "displayValue": "Okinawa"
        }
      }
    },
    "FR": {
      "countryId": "FR",
      "displayValue": "France",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "KW": {
      "countryId": "KW",
      "displayValue": "Kuwait",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "LV": {
      "countryId": "LV",
      "displayValue": "Latvia",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(LV-\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "states": {}
    },
    "IE": {
      "countryId": "IE",
      "displayValue": "Ireland",
      "stateDisplayValue": "County",
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "\\b(?:(a(4[125s]|6[37]|7[5s]|[8b][1-6s]|9[12468b])|c1[5s]|d([0o][1-9sb]|1[0-8osb]|2[024o]|6w)|e(2[15s]|3[24]|4[15s]|[5s]3|91)|f(12|2[368b]|3[15s]|4[25s]|[5s][26]|9[1-4])|h(1[2468b]|23|[5s][34]|6[25s]|[79]1)|k(3[246]|4[5s]|[5s]6|67|7[8b])|n(3[79]|[49]1)|p(1[247]|2[45s]|3[126]|4[37]|[5s][16]|6[17]|7[25s]|[8b][15s])|r(14|21|3[25s]|4[25s]|[5s][16]|9[35s])|t(12|23|34|4[5s]|[5s]6)|v(1[45s]|23|3[15s]|42|9[2-5s])|w(12|23|34|91)|x(3[5s]|42|91)|y(14|2[15s]|3[45s]))\\s?[abcdefhknoprtsvwxy\\d]{4})\\b",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{province}_{zip}_{country}_{phone}",
      "states": {
        "CW": {
          "stateValue": "CW",
          "displayValue": "Carlow"
        },
        "CN": {
          "stateValue": "CN",
          "displayValue": "Cavan"
        },
        "CE": {
          "stateValue": "CE",
          "displayValue": "Clare"
        },
        "CO": {
          "stateValue": "CO",
          "displayValue": "Cork"
        },
        "DL": {
          "stateValue": "DL",
          "displayValue": "Donegal"
        },
        "D": {
          "stateValue": "D",
          "displayValue": "Dublin"
        },
        "G": {
          "stateValue": "G",
          "displayValue": "Galway"
        },
        "KY": {
          "stateValue": "KY",
          "displayValue": "Kerry"
        },
        "KE": {
          "stateValue": "KE",
          "displayValue": "Kildare"
        },
        "KK": {
          "stateValue": "KK",
          "displayValue": "Kilkenny"
        },
        "LS": {
          "stateValue": "LS",
          "displayValue": "Laois"
        },
        "LM": {
          "stateValue": "LM",
          "displayValue": "Leitrim"
        },
        "LK": {
          "stateValue": "LK",
          "displayValue": "Limerick"
        },
        "LD": {
          "stateValue": "LD",
          "displayValue": "Longford"
        },
        "LH": {
          "stateValue": "LH",
          "displayValue": "Louth"
        },
        "MO": {
          "stateValue": "MO",
          "displayValue": "Mayo"
        },
        "MH": {
          "stateValue": "MH",
          "displayValue": "Meath"
        },
        "MN": {
          "stateValue": "MN",
          "displayValue": "Monaghan"
        },
        "OY": {
          "stateValue": "OY",
          "displayValue": "Offaly"
        },
        "RN": {
          "stateValue": "RN",
          "displayValue": "Roscommon"
        },
        "SO": {
          "stateValue": "SO",
          "displayValue": "Sligo"
        },
        "TA": {
          "stateValue": "TA",
          "displayValue": "Tipperary"
        },
        "WD": {
          "stateValue": "WD",
          "displayValue": "Waterford"
        },
        "WH": {
          "stateValue": "WH",
          "displayValue": "Westmeath"
        },
        "WX": {
          "stateValue": "WX",
          "displayValue": "Wexford"
        },
        "WW": {
          "stateValue": "WW",
          "displayValue": "Wicklow"
        }
      }
    },
    "BF": {
      "countryId": "BF",
      "displayValue": "Burkina Faso",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "AU": {
      "countryId": "AU",
      "displayValue": "Australia",
      "stateDisplayValue": "State",
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {province} {zip}_{country}_{phone}",
      "states": {
        "ACT": {
          "stateValue": "ACT",
          "displayValue": "Australian Capital Territory"
        },
        "NSW": {
          "stateValue": "NSW",
          "displayValue": "New South Wales"
        },
        "NT": {
          "stateValue": "NT",
          "displayValue": "Northern Territory"
        },
        "QLD": {
          "stateValue": "QLD",
          "displayValue": "Queensland"
        },
        "SA": {
          "stateValue": "SA",
          "displayValue": "South Australia"
        },
        "TAS": {
          "stateValue": "TAS",
          "displayValue": "Tasmania"
        },
        "VIC": {
          "stateValue": "VIC",
          "displayValue": "Victoria"
        },
        "WA": {
          "stateValue": "WA",
          "displayValue": "Western Australia"
        }
      }
    },
    "GN": {
      "countryId": "GN",
      "displayValue": "Guinea",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "TL": {
      "countryId": "TL",
      "displayValue": "Timor-Leste",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "AR": {
      "countryId": "AR",
      "displayValue": "Argentina",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{4}|[A-Z]\\d{4}[A-Z]{3})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
      "states": {
        "B": {
          "stateValue": "B",
          "displayValue": "Buenos Aires Province"
        },
        "K": {
          "stateValue": "K",
          "displayValue": "Catamarca"
        },
        "H": {
          "stateValue": "H",
          "displayValue": "Chaco"
        },
        "U": {
          "stateValue": "U",
          "displayValue": "Chubut"
        },
        "C": {
          "stateValue": "C",
          "displayValue": "Buenos Aires (Autonomous City)"
        },
        "X": {
          "stateValue": "X",
          "displayValue": "Córdoba"
        },
        "W": {
          "stateValue": "W",
          "displayValue": "Corrientes"
        },
        "E": {
          "stateValue": "E",
          "displayValue": "Entre Ríos"
        },
        "P": {
          "stateValue": "P",
          "displayValue": "Formosa"
        },
        "Y": {
          "stateValue": "Y",
          "displayValue": "Jujuy"
        },
        "L": {
          "stateValue": "L",
          "displayValue": "La Pampa"
        },
        "F": {
          "stateValue": "F",
          "displayValue": "La Rioja"
        },
        "M": {
          "stateValue": "M",
          "displayValue": "Mendoza"
        },
        "N": {
          "stateValue": "N",
          "displayValue": "Misiones"
        },
        "Q": {
          "stateValue": "Q",
          "displayValue": "Neuquén"
        },
        "R": {
          "stateValue": "R",
          "displayValue": "Río Negro"
        },
        "A": {
          "stateValue": "A",
          "displayValue": "Salta"
        },
        "J": {
          "stateValue": "J",
          "displayValue": "San Juan"
        },
        "D": {
          "stateValue": "D",
          "displayValue": "San Luis"
        },
        "Z": {
          "stateValue": "Z",
          "displayValue": "Santa Cruz"
        },
        "S": {
          "stateValue": "S",
          "displayValue": "Santa Fe"
        },
        "G": {
          "stateValue": "G",
          "displayValue": "Santiago del Estero"
        },
        "V": {
          "stateValue": "V",
          "displayValue": "Tierra del Fuego"
        },
        "T": {
          "stateValue": "T",
          "displayValue": "Tucumán"
        }
      }
    },
    "BN": {
      "countryId": "BN",
      "displayValue": "Brunei Darussalam",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^([A-Z]{2}\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "CV": {
      "countryId": "CV",
      "displayValue": "Cape Verde",
      "stateDisplayValue": "Municipality",
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "CR": {
      "countryId": "CR",
      "displayValue": "Costa Rica",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "MR": {
      "countryId": "MR",
      "displayValue": "Mauritania",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "CL": {
      "countryId": "CL",
      "displayValue": "Chile",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{7})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{province}_{country}_{phone}",
      "states": {
        "AP": {
          "stateValue": "AP",
          "displayValue": "Arica y Parinacota"
        },
        "TA": {
          "stateValue": "TA",
          "displayValue": "Tarapacá"
        },
        "AN": {
          "stateValue": "AN",
          "displayValue": "Antofagasta"
        },
        "AT": {
          "stateValue": "AT",
          "displayValue": "Atacama"
        },
        "CO": {
          "stateValue": "CO",
          "displayValue": "Coquimbo"
        },
        "VS": {
          "stateValue": "VS",
          "displayValue": "Valparaíso"
        },
        "RM": {
          "stateValue": "RM",
          "displayValue": "Santiago Metropolitan"
        },
        "LI": {
          "stateValue": "LI",
          "displayValue": "Libertador General Bernardo O’Higgins"
        },
        "ML": {
          "stateValue": "ML",
          "displayValue": "Maule"
        },
        "NB": {
          "stateValue": "NB",
          "displayValue": "Ñuble"
        },
        "BI": {
          "stateValue": "BI",
          "displayValue": "Bío Bío"
        },
        "AR": {
          "stateValue": "AR",
          "displayValue": "Araucanía"
        },
        "LR": {
          "stateValue": "LR",
          "displayValue": "Los Ríos"
        },
        "LL": {
          "stateValue": "LL",
          "displayValue": "Los Lagos"
        },
        "AI": {
          "stateValue": "AI",
          "displayValue": "Aysén"
        },
        "MA": {
          "stateValue": "MA",
          "displayValue": "Magallanes Region"
        }
      }
    },
    "VN": {
      "countryId": "VN",
      "displayValue": "Vietnam",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{6})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "states": {
        "VN-44": {
          "stateValue": "VN-44",
          "displayValue": "An Giang"
        },
        "VN-43": {
          "stateValue": "VN-43",
          "displayValue": "Ba Ria - Vung Tau"
        },
        "VN-54": {
          "stateValue": "VN-54",
          "displayValue": "Bac Giang"
        },
        "VN-53": {
          "stateValue": "VN-53",
          "displayValue": "Bac Kan"
        },
        "VN-55": {
          "stateValue": "VN-55",
          "displayValue": "Bac Lieu"
        },
        "VN-56": {
          "stateValue": "VN-56",
          "displayValue": "Bac Ninh"
        },
        "VN-50": {
          "stateValue": "VN-50",
          "displayValue": "Ben Tre"
        },
        "VN-31": {
          "stateValue": "VN-31",
          "displayValue": "Binh Dinh"
        },
        "VN-57": {
          "stateValue": "VN-57",
          "displayValue": "Binh Duong"
        },
        "VN-58": {
          "stateValue": "VN-58",
          "displayValue": "Binh Phuoc"
        },
        "VN-40": {
          "stateValue": "VN-40",
          "displayValue": "Binh Thuan"
        },
        "VN-59": {
          "stateValue": "VN-59",
          "displayValue": "Ca Mau"
        },
        "VN-04": {
          "stateValue": "VN-04",
          "displayValue": "Cao Bang"
        },
        "VN-33": {
          "stateValue": "VN-33",
          "displayValue": "Dak Lak"
        },
        "VN-72": {
          "stateValue": "VN-72",
          "displayValue": "Dak Nong"
        },
        "VN-71": {
          "stateValue": "VN-71",
          "displayValue": "Dien Bien"
        },
        "VN-39": {
          "stateValue": "VN-39",
          "displayValue": "Dong Nai"
        },
        "VN-45": {
          "stateValue": "VN-45",
          "displayValue": "Dong Thap"
        },
        "VN-30": {
          "stateValue": "VN-30",
          "displayValue": "Gia Lai"
        },
        "VN-03": {
          "stateValue": "VN-03",
          "displayValue": "Ha Giang"
        },
        "VN-63": {
          "stateValue": "VN-63",
          "displayValue": "Ha Nam"
        },
        "VN-23": {
          "stateValue": "VN-23",
          "displayValue": "Ha Tinh"
        },
        "VN-61": {
          "stateValue": "VN-61",
          "displayValue": "Hai Duong"
        },
        "VN-73": {
          "stateValue": "VN-73",
          "displayValue": "Hau Giang"
        },
        "VN-14": {
          "stateValue": "VN-14",
          "displayValue": "Hoa Binh"
        },
        "VN-66": {
          "stateValue": "VN-66",
          "displayValue": "Hung Yen"
        },
        "VN-34": {
          "stateValue": "VN-34",
          "displayValue": "Khanh Hoa"
        },
        "VN-47": {
          "stateValue": "VN-47",
          "displayValue": "Kien Giang"
        },
        "VN-28": {
          "stateValue": "VN-28",
          "displayValue": "Kon Tum"
        },
        "VN-01": {
          "stateValue": "VN-01",
          "displayValue": "Lai Chau"
        },
        "VN-35": {
          "stateValue": "VN-35",
          "displayValue": "Lam Dong"
        },
        "VN-09": {
          "stateValue": "VN-09",
          "displayValue": "Lang Son"
        },
        "VN-02": {
          "stateValue": "VN-02",
          "displayValue": "Lao Cai"
        },
        "VN-41": {
          "stateValue": "VN-41",
          "displayValue": "Long An"
        },
        "VN-67": {
          "stateValue": "VN-67",
          "displayValue": "Nam Dinh"
        },
        "VN-22": {
          "stateValue": "VN-22",
          "displayValue": "Nghe An"
        },
        "VN-18": {
          "stateValue": "VN-18",
          "displayValue": "Ninh Binh"
        },
        "VN-36": {
          "stateValue": "VN-36",
          "displayValue": "Ninh Thuan"
        },
        "VN-68": {
          "stateValue": "VN-68",
          "displayValue": "Phu Tho"
        },
        "VN-32": {
          "stateValue": "VN-32",
          "displayValue": "Phu Yen"
        },
        "VN-24": {
          "stateValue": "VN-24",
          "displayValue": "Quang Binh"
        },
        "VN-27": {
          "stateValue": "VN-27",
          "displayValue": "Quang Nam"
        },
        "VN-29": {
          "stateValue": "VN-29",
          "displayValue": "Quang Ngai"
        },
        "VN-13": {
          "stateValue": "VN-13",
          "displayValue": "Quang Ninh"
        },
        "VN-25": {
          "stateValue": "VN-25",
          "displayValue": "Quang Tri"
        },
        "VN-52": {
          "stateValue": "VN-52",
          "displayValue": "Soc Trang"
        },
        "VN-05": {
          "stateValue": "VN-05",
          "displayValue": "Son La"
        },
        "VN-37": {
          "stateValue": "VN-37",
          "displayValue": "Tay Ninh"
        },
        "VN-20": {
          "stateValue": "VN-20",
          "displayValue": "Thai Binh"
        },
        "VN-69": {
          "stateValue": "VN-69",
          "displayValue": "Thai Nguyen"
        },
        "VN-21": {
          "stateValue": "VN-21",
          "displayValue": "Thanh Hoa"
        },
        "VN-26": {
          "stateValue": "VN-26",
          "displayValue": "Thua Thien-hue"
        },
        "VN-46": {
          "stateValue": "VN-46",
          "displayValue": "Tien Giang"
        },
        "VN-51": {
          "stateValue": "VN-51",
          "displayValue": "Tra Vinh"
        },
        "VN-07": {
          "stateValue": "VN-07",
          "displayValue": "Tuyen Quang"
        },
        "VN-49": {
          "stateValue": "VN-49",
          "displayValue": "Vinh Long"
        },
        "VN-70": {
          "stateValue": "VN-70",
          "displayValue": "Vinh Phuc"
        },
        "VN-06": {
          "stateValue": "VN-06",
          "displayValue": "Yen Bai"
        },
        "VN-CT": {
          "stateValue": "VN-CT",
          "displayValue": "Can Tho"
        },
        "VN-DN": {
          "stateValue": "VN-DN",
          "displayValue": "Da Nang"
        },
        "VN-HN": {
          "stateValue": "VN-HN",
          "displayValue": "Ha Noi"
        },
        "VN-HP": {
          "stateValue": "VN-HP",
          "displayValue": "Hai Phong"
        },
        "VN-SG": {
          "stateValue": "VN-SG",
          "displayValue": "Ho Chi Minh(local Variant Is Sai Gon)"
        }
      }
    },
    "ZW": {
      "countryId": "ZW",
      "displayValue": "Zimbabwe",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "AD": {
      "countryId": "AD",
      "displayValue": "Andorra",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(AD\\d{3})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "UA": {
      "countryId": "UA",
      "displayValue": "Ukraine",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "states": {}
    },
    "LT": {
      "countryId": "LT",
      "displayValue": "Lithuania",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "RW": {
      "countryId": "RW",
      "displayValue": "Rwanda",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "TD": {
      "countryId": "TD",
      "displayValue": "Chad",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "ZM": {
      "countryId": "ZM",
      "displayValue": "Zambia",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "MC": {
      "countryId": "MC",
      "displayValue": "Monaco",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "GH": {
      "countryId": "GH",
      "displayValue": "Ghana",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "PL": {
      "countryId": "PL",
      "displayValue": "Poland",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{2}-\\d{3})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "CA": {
      "countryId": "CA",
      "displayValue": "Canada",
      "stateDisplayValue": "Province",
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^([A-Z]\\d[A-Z](\\s)*\\d[A-Z]\\d)$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {province} {zip}_{country}_{phone}",
      "states": {
        "AB": {
          "stateValue": "AB",
          "displayValue": "Alberta"
        },
        "BC": {
          "stateValue": "BC",
          "displayValue": "British Columbia"
        },
        "MB": {
          "stateValue": "MB",
          "displayValue": "Manitoba"
        },
        "NB": {
          "stateValue": "NB",
          "displayValue": "New Brunswick"
        },
        "NL": {
          "stateValue": "NL",
          "displayValue": "Newfoundland and Labrador"
        },
        "NT": {
          "stateValue": "NT",
          "displayValue": "Northwest Territories"
        },
        "NS": {
          "stateValue": "NS",
          "displayValue": "Nova Scotia"
        },
        "NU": {
          "stateValue": "NU",
          "displayValue": "Nunavut"
        },
        "ON": {
          "stateValue": "ON",
          "displayValue": "Ontario"
        },
        "PE": {
          "stateValue": "PE",
          "displayValue": "Prince Edward Island"
        },
        "QC": {
          "stateValue": "QC",
          "displayValue": "Quebec"
        },
        "SK": {
          "stateValue": "SK",
          "displayValue": "Saskatchewan"
        },
        "YT": {
          "stateValue": "YT",
          "displayValue": "Yukon"
        }
      }
    },
    "EC": {
      "countryId": "EC",
      "displayValue": "Ecuador",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(EC\\d{6})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip}_{city}_{country}_{phone}",
      "states": {}
    },
    "PA": {
      "countryId": "PA",
      "displayValue": "Panama",
      "stateDisplayValue": "Province",
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": true,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{province}_{country}_{phone}",
      "states": {
        "PA-1": {
          "stateValue": "PA-1",
          "displayValue": "Bocas del Toro"
        },
        "PA-4": {
          "stateValue": "PA-4",
          "displayValue": "Chiriquí"
        },
        "PA-2": {
          "stateValue": "PA-2",
          "displayValue": "Coclé"
        },
        "PA-3": {
          "stateValue": "PA-3",
          "displayValue": "Colón"
        },
        "PA-5": {
          "stateValue": "PA-5",
          "displayValue": "Darién"
        },
        "PA-EM": {
          "stateValue": "PA-EM",
          "displayValue": "Emberá"
        },
        "PA-6": {
          "stateValue": "PA-6",
          "displayValue": "Herrera"
        },
        "PA-KY": {
          "stateValue": "PA-KY",
          "displayValue": "Guna Yala"
        },
        "PA-7": {
          "stateValue": "PA-7",
          "displayValue": "Los Santos"
        },
        "PA-NB": {
          "stateValue": "PA-NB",
          "displayValue": "Ngöbe-Buglé"
        },
        "PA-8": {
          "stateValue": "PA-8",
          "displayValue": "Panamá"
        },
        "PA-10": {
          "stateValue": "PA-10",
          "displayValue": "West Panamá"
        },
        "PA-9": {
          "stateValue": "PA-9",
          "displayValue": "Veraguas"
        }
      }
    },
    "TG": {
      "countryId": "TG",
      "displayValue": "Togo",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "SG": {
      "countryId": "SG",
      "displayValue": "Singapore",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{6})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "states": {}
    },
    "MT": {
      "countryId": "MT",
      "displayValue": "Malta",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^([A-Z]{3}(\\s)*\\d{4})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "states": {}
    },
    "BO": {
      "countryId": "BO",
      "displayValue": "Bolivia, Plurinational State of",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "states": {}
    },
    "IN": {
      "countryId": "IN",
      "displayValue": "India",
      "stateDisplayValue": "State",
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": true,
      "postalCodeRegex": "^(\\d{6})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
      "states": {
        "AN": {
          "stateValue": "AN",
          "displayValue": "Andaman and Nicobar Islands"
        },
        "AP": {
          "stateValue": "AP",
          "displayValue": "Andhra Pradesh"
        },
        "AR": {
          "stateValue": "AR",
          "displayValue": "Arunachal Pradesh"
        },
        "AS": {
          "stateValue": "AS",
          "displayValue": "Assam"
        },
        "BR": {
          "stateValue": "BR",
          "displayValue": "Bihar"
        },
        "CH": {
          "stateValue": "CH",
          "displayValue": "Chandigarh"
        },
        "CG": {
          "stateValue": "CG",
          "displayValue": "Chhattisgarh"
        },
        "DN": {
          "stateValue": "DN",
          "displayValue": "Dadra and Nagar Haveli"
        },
        "DD": {
          "stateValue": "DD",
          "displayValue": "Daman and Diu"
        },
        "DL": {
          "stateValue": "DL",
          "displayValue": "Delhi"
        },
        "GA": {
          "stateValue": "GA",
          "displayValue": "Goa"
        },
        "GJ": {
          "stateValue": "GJ",
          "displayValue": "Gujarat"
        },
        "HR": {
          "stateValue": "HR",
          "displayValue": "Haryana"
        },
        "HP": {
          "stateValue": "HP",
          "displayValue": "Himachal Pradesh"
        },
        "JK": {
          "stateValue": "JK",
          "displayValue": "Jammu and Kashmir"
        },
        "JH": {
          "stateValue": "JH",
          "displayValue": "Jharkhand"
        },
        "KA": {
          "stateValue": "KA",
          "displayValue": "Karnataka"
        },
        "KL": {
          "stateValue": "KL",
          "displayValue": "Kerala"
        },
        "LA": {
          "stateValue": "LA",
          "displayValue": "Ladakh"
        },
        "LD": {
          "stateValue": "LD",
          "displayValue": "Lakshadweep"
        },
        "MP": {
          "stateValue": "MP",
          "displayValue": "Madhya Pradesh"
        },
        "MH": {
          "stateValue": "MH",
          "displayValue": "Maharashtra"
        },
        "MN": {
          "stateValue": "MN",
          "displayValue": "Manipur"
        },
        "ML": {
          "stateValue": "ML",
          "displayValue": "Meghalaya"
        },
        "MZ": {
          "stateValue": "MZ",
          "displayValue": "Mizoram"
        },
        "NL": {
          "stateValue": "NL",
          "displayValue": "Nagaland"
        },
        "OR": {
          "stateValue": "OR",
          "displayValue": "Odisha"
        },
        "PY": {
          "stateValue": "PY",
          "displayValue": "Puducherry"
        },
        "PB": {
          "stateValue": "PB",
          "displayValue": "Punjab"
        },
        "RJ": {
          "stateValue": "RJ",
          "displayValue": "Rajasthan"
        },
        "SK": {
          "stateValue": "SK",
          "displayValue": "Sikkim"
        },
        "TN": {
          "stateValue": "TN",
          "displayValue": "Tamil Nadu"
        },
        "TS": {
          "stateValue": "TS",
          "displayValue": "Telangana"
        },
        "TR": {
          "stateValue": "TR",
          "displayValue": "Tripura"
        },
        "UP": {
          "stateValue": "UP",
          "displayValue": "Uttar Pradesh"
        },
        "UK": {
          "stateValue": "UK",
          "displayValue": "Uttarakhand"
        },
        "WB": {
          "stateValue": "WB",
          "displayValue": "West Bengal"
        }
      }
    },
    "MF": {
      "countryId": "MF",
      "displayValue": "Saint Martin (French Part)",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": false,
      "stateRequired": false,
      "postalCodeRegex": null,
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    },
    "RE": {
      "countryId": "RE",
      "displayValue": "Réunion",
      "stateDisplayValue": null,
      "cityDisplayValue": null,
      "postalCodeRequired": true,
      "stateRequired": false,
      "postalCodeRegex": "^(\\d{5})$",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "states": {}
    }
  }
};

export default countriesAndStates;

const {countries} = countriesAndStates;
const countryEntries = Object.entries(countries);
countryEntries.sort(([_a, a], [_b, b]) => {
  if(a.displayValue < b.displayValue) { return -1; }
  if(a.displayValue > b.displayValue) { return 1; }
  return 0;
});

export const ascCountryCodes = countryEntries.map(e => e[0]);

function renderLineTemplate(countryFieldSettings: Country, fields: string, address: UserAddress) {
  const mapper = {
    fullName: 'firstName',
    address: 'address1',
    unitNumber: 'address2',
    city: 'city',
    country: 'country',
    phoneNumber: 'phone',
    postalCode: 'zip',
    state: 'province'
  };

  let line = fields
    .replace('{company}', '')
    .replace('{lastName}', '');
  for (const [key, formatKey] of Object.entries(mapper) as [keyof typeof mapper, string][]) {
    let value = address[key];

    switch (key) {
      case 'country':
        value = countriesAndStates.countries[value as string].displayValue;
        break;
      case 'state': {
        const stateData = countriesAndStates.countries[address.country].states[value as string];
        if (stateData) {
          value = stateData.displayValue;
        }
        break;
      }
    }
    line = line.replace(`{${formatKey}}`, value || '');
  }

  return line;
}

export function formatAddress(address: UserAddress) {
  const countryFieldSettings = countriesAndStates.countries[address.country];
  if (!countryFieldSettings) {
    return [
      address.fullName,
      address.phoneNumber,
      address.address,
      address.unitNumber,
      address.country,
      address.state,
      address.city,
      address.postalCode,
      '* Unable to format address, please format manually when posting'
    ].filter(line => !!line);
  }

  return countryFieldSettings.displayFormat
    .split('_')
    .map(fields => renderLineTemplate(countryFieldSettings, fields, address).trim())
    .filter(line => line.length > 0);
}